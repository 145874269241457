import { Cart, isCart } from '@integrabeauty/shopify-ajax-api';

/**
 * Returns whether two carts are equal. This is an approximate check that only considers line items.
 * It does not consider attributes and discounts.
 */
export function hasSimilarItems(cart1: Cart, cart2: Cart) {
  if (!isCart(cart1)) {
    return false;
  }

  if (!isCart(cart2)) {
    return false;
  }

  if (cart1.token && cart2.token && cart1.token !== cart2.token) {
    return false;
  }

  // The same number of line items should be present in both carts.
  if (cart1.items.length !== cart2.items.length) {
    return false;
  }

  // The lines should have the same keys with the same quantities. If any one line does not, break.
  // Because there are the same number of line items in both carts, finding that every item from
  // cart1 exists in cart2 implies that every item from cart2 exists in cart1, so we only need to
  // examine state in one direction.
  for (const item1 of cart1.items) {
    let found = false;
    for (const item2 of cart2.items) {
      if (item1.key === item2.key && item1.quantity === item2.quantity) {
        found = true;
        break;
      }
    }

    if (!found) {
      return false;
    }
  }

  return true;
}
