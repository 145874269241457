import type { CustomElement } from '@integrabeauty/custom-elements';
import type { Cart } from '@integrabeauty/shopify-ajax-api';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders the cart's totals. Displays the subtotal of all line items, the total discount amount,
 * and the discount percentage (if any).
 */
class CartTotals extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-estimated-total'
    ];
  }

  readonly dataset!: {
    /**
     * ISO currency code.
     *
     * @example "USD"
     */
    currencyCode: string;

    /**
     * Locale specific currency characters.
     *
     * @example "$"
     */
    currencySymbol: string;

    /**
     * The price before discounts less the price after discounts.
     */
    discountAmount: string;

    /**
     * The price after discounts divided by the price before discounts, as a percentage.
     */
    discountPercent: string;

    /**
     * The price after discounts but before taxes and shipping, which are unknown until checkout.
     */
    estimatedTotal: string;

    /**
     * The price before discounts.
     */
    subtotalAmount: string;
  };

  public shadowRoot!: ShadowRoot;
  private onCartUpdatedBound = this.onCartUpdated.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    for (const event of cart_drawer_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);

    this.render();
  }

  public disconnectedCallback() {
    removeEventListener('cart-updated', this.onCartUpdatedBound);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    // Hide discount row if discount is $0 (which is the same as subtotal = estimated total)
    const discountRow = this.shadowRoot.getElementById('discount-row');
    if (this.dataset.discountAmount === '0') {
      discountRow.classList.add('hidden');
    } else {
      discountRow.classList.remove('hidden');
    }

    const subtotalEl = this.shadowRoot.getElementById('subtotal');
    subtotalEl.dataset.currencyCode = this.dataset.currencyCode;
    subtotalEl.dataset.currencySymbol = this.dataset.currencySymbol;
    subtotalEl.dataset.cents = this.dataset.subtotalAmount;

    const discountTotalEl = this.shadowRoot.getElementById('discount');
    discountTotalEl.dataset.currencyCode = this.dataset.currencyCode;
    discountTotalEl.dataset.currencySymbol = this.dataset.currencySymbol;
    discountTotalEl.dataset.cents = this.dataset.discountAmount;

    this.shadowRoot.getElementById('discount-percentage').textContent =
      `(${this.dataset.discountPercent}%)`;
  }

  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    const cart = event.detail.cart;
    if (cart.items.length === 0) {
      return;
    }

    const subtotal = computeSubtotal(cart);

    this.dataset.subtotalAmount = subtotal.toString();

    const applied = this.shadowRoot.querySelector<HTMLElement>('#order-discounts-row');
    applied.style.display = 'none';

    let orderDiscountsValue = 0;
    for (const discount of cart.cart_level_discount_applications) {
      if (discount.total_allocated_amount > 0) {
        orderDiscountsValue += discount.total_allocated_amount;
      }
    }

    if (orderDiscountsValue > 0) {
      // If a cart level discount is applied, we unset display none to ensure "Order Discounts"
      // heading is visible.
      applied.style.display = 'flex';

      const orderDiscountsEl = this.shadowRoot.querySelector<HTMLElement>('#order-discount-total');
      orderDiscountsEl.dataset.currencySymbol = this.dataset.currencySymbol;
      orderDiscountsEl.dataset.currencyCode = this.dataset.currencyCode;
      orderDiscountsEl.dataset.cents = orderDiscountsValue.toString();
    }

    // We cannot use the original discount amount because that is the difference between the sales
    // price and the discounted price. The sales price is the implicitly discounted MSRP. We want
    // the different from the MSRP.

    // The total after discounts, e.g. the estimated total less taxes and shipping that someone will
    // pay, is the total_price property. Because total_price is floating point, we want to round
    // to two significant digits.

    const discountAmount = Math.round(100 * (subtotal - cart.total_price)) / 100;

    this.dataset.discountAmount = discountAmount.toString();

    // The discount percent must be manipulated to be based upon our custom subtotal so that it
    // represents in the reduction of the MSRP, not the sales price. We multiply by 100 so that we
    // get a nice visual percentage (e.g. a 10% discount). We use floor instead of round so that
    // we never over-represent the percentage. (e.g. 5.5% off is 5% off, not 6%).

    const discountPercent = Math.floor(100 * discountAmount / subtotal);
    this.dataset.discountPercent = discountPercent.toString();

    // Set this attribute last because this triggers a render
    this.dataset.estimatedTotal = cart.total_price.toString();
  }
}

/**
 * The subtotal should be the total of the compare at price elements. Because we are working with
 * compare at prices, we cannot use the cart's price before discounts fields. We have to calculate
 * this on our own.
 *
 * There was an implementation choice to compute this in the server side rendered cart state in
 * liquid or to compute this here. It seems better to do it here because JavaScript is much more
 * expressive and the cost of performing the calculation here is not significant and because this
 * clearly documents the logic and is easier to maintain.
 *
 * @todo this can be further simplified
 */
function computeSubtotal(cart: Cart) {
  let subtotal = 0;
  for (const item of cart.items) {
    const compareAtPrice = parseInt(item.properties._value_set_compare_price, 10);
    if (Number.isInteger(compareAtPrice)) {
      subtotal += compareAtPrice;
    } else {
      subtotal += item.original_line_price;
    }
  }

  // If doing some math somehow resulted in floating point junk, remove it. This generally does
  // not happen in browsers that we tested but you never know.
  subtotal = Math.floor(subtotal);

  return subtotal;
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

declare global {
  interface HTMLElementTagNameMap {
    'cart-totals': CartTotals;
  }
}

if (!customElements.get('cart-totals')) {
  customElements.define('cart-totals', CartTotals);
}
