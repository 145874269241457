import * as uuid from 'uuid';
import './cart-actions/index.js';
import './cart-drawer/index.js';
import './cart/index.js';
import './cart-line-item/index.js';
import './cart-line-items/index.js';
import './cart-messages/index.js';
import './cart-totals/index.js';
import './cart-upsell-item/index.js';
import './cart-upsell-items/index.js';
import './discount-badge/index.js';
import './free-shipping-banner/index.js';
import './discount-input/index.js';
import './promo-goal/index.js';
import './promo-goal-progress/index.js';
import './upsell-modal/index.js';

/**
 * Opens the side cart. Emits a cart-drawer-opened event.
 *
 * The cart-drawer-opened event is primarily only intended for downstream analytics, not managing UI
 * state. Avoid implementing any ui management logic that uses this event.
 */
function onCartOpenRequested(event: WindowEventMap['cart-open-requested']) {
  const cartDrawer = document.querySelector('cart-drawer');
  if (!cartDrawer || cartDrawer.dataset.show === 'true') {
    return;
  }

  const animate = event.detail?.animate ?? true;
  cartDrawer.dataset.animate = animate ? 'true' : 'false';
  cartDrawer.dataset.show = 'true';

  type ModalShowRequestedEvent = WindowEventMap['modal-show-requested'];
  const modalShowEvent = new CustomEvent<ModalShowRequestedEvent['detail']>(
    'modal-show-requested', {
      detail: {
        element: cartDrawer
      }
    });
  dispatchEvent(modalShowEvent);

  addEventListener('shroud-clicked', onShroudClicked);

  type CartDrawerOpenedEvent = WindowEventMap['cart-drawer-opened'];
  const openedEvent = new CustomEvent<CartDrawerOpenedEvent['detail']>('cart-drawer-opened', {
    detail: {
      event_id: uuid.v4()
    }
  });
  dispatchEvent(openedEvent);
}

/**
 * Closes the cart drawer.
 */
function close(animate: boolean) {
  removeEventListener('shroud-clicked', onShroudClicked);

  const cartDrawer = document.querySelector('cart-drawer');
  if (!cartDrawer) {
    return;
  }

  cartDrawer.dataset.animate = animate ? 'true' : 'false';
  cartDrawer.dataset.show = 'false';

  type Detail = WindowEventMap['modal-close-requested']['detail'];
  const modalCloseEvent = new CustomEvent<Detail>('modal-close-requested',
    { detail: { element: cartDrawer } }
  );
  dispatchEvent(modalCloseEvent);

  dispatchEvent(new Event('cart-drawer-closed'));
}

function onCartCloseRequested(event: WindowEventMap['cart-close-requested']) {
  const animate = event.detail?.animate ?? true;
  close(animate);
}

function onShroudClicked(_event: Event) {
  close(true);
}

addEventListener('cart-close-requested', onCartCloseRequested);
addEventListener('cart-open-requested', onCartOpenRequested);
