import type { CustomElement } from '@integrabeauty/custom-elements';
import type { Item } from '@integrabeauty/shopify-ajax-api';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders cart state and provides UI for adding and removing items and starting checkout
 */
class CartElement extends HTMLElement implements CustomElement {
  readonly dataset!: {
    /**
     * URL for header "our best sellers" anchor link
     */
    bestSellersUrl: string;

    /**
     * Country ISO code. Required.
     *
     * @example "US"
     */
    countryCode: string;

    /**
     * Currency ISO code. Required.
     *
     * @example "US"
     */
    currencyCode: string;

    /**
     * Currency Symbol. Required.
     *
     * @example "US"
     */
    currencySymbol: string;

    /**
     * The current customer id, representing whether the visitor is authenticated. Affects the
     * visibility of some content.
     */
    customerId: string;

    /**
     * The name of the current Shopify template. Affects whether the side cart is displayed.
     */
    templateName: string;
  };

  private onCartTransactionStartedBound = this.onCartTransactionStarted.bind(this);
  private onCartTransactionCompletedBound = this.onCartTransactionCompleted.bind(this);
  private onCartUpdatedBound = this.onCartUpdated.bind(this);
  private onContinueShoppingButtonClickBound = this.onContinueShoppingButtonClick.bind(this);
  private onDOMContentLoadedBound = this.onDOMContentLoaded.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const continueShopping = this.shadowRoot.querySelector<HTMLElement>('#continue-shopping');
    continueShopping.addEventListener('click', this.onContinueShoppingButtonClickBound);

    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      setTimeout(this.onDOMContentLoadedBound);
    } else {
      addEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
    }
  }

  public disconnectedCallback() {
    const continueShopping = this.shadowRoot.querySelector<HTMLElement>('#continue-shopping');
    continueShopping?.removeEventListener('click', this.onContinueShoppingButtonClickBound);

    removeEventListener('cart-transaction-started', this.onCartTransactionStarted);
    removeEventListener('cart-transaction-completed', this.onCartTransactionCompletedBound);
    removeEventListener('cart-updated', this.onCartUpdatedBound);

    removeEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
  }

  private onDOMContentLoaded(_event: Event) {
    if (this.dataset.customerId) {
      const loggedInEls = this.querySelectorAll('.logged-in');
      for (const loggedInEl of loggedInEls) {
        loggedInEl.classList.remove('hidden');
      }
    } else {
      const guestEls = this.querySelectorAll('.guest');
      for (const guestEl of guestEls) {
        guestEl.classList.remove('hidden');
      }
    }

    if (this.dataset.bestSellersUrl) {
      const bestSellersEl = this.shadowRoot.querySelector<HTMLAnchorElement>('a.best-sellers-link');
      bestSellersEl.href = this.dataset.bestSellersUrl;
    }

    addEventListener('cart-transaction-started', this.onCartTransactionStartedBound);
    addEventListener('cart-transaction-completed', this.onCartTransactionCompletedBound);

    for (const event of cart_drawer_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);
  }

  private onContinueShoppingButtonClick(_event: MouseEvent) {
    // if user is on the cart page there is no cart drawer and the "Continue Shopping" CTA should
    // instead route the user to the home page.
    if (this.dataset.templateName === 'cart') {
      location.href = location.origin;
      return;
    }

    dispatchEvent(new Event('cart-close-requested'));
  }

  private onCartUpdatedInitial(event: WindowEventMap['cart-updated']) {
    const cart = event.detail.cart;

    if (cart.items.length > 0) {
      this.shadowRoot.querySelector('#cart-contents').classList.remove('hidden');
      this.shadowRoot.querySelector('.footer').classList.remove('hidden');
      this.querySelector('cart-line-items')?.setAttribute('role', 'list');
    } else {
      const emptyCart = this.shadowRoot.querySelector('#empty-cart');
      // TODO: the optional chaining here is important. for some reason this routinely fails to
      // find the empty cart element. we need to investigate why this is happening.
      emptyCart?.classList.remove('hidden');
    }

    // We will load only one of these
    const promo = this.querySelector('free-shipping-banner, promo-goal-progress');

    const installmentsMessage = this.querySelector('installment-payment-message');

    if (this.dataset.countryCode !== 'US') {
      installmentsMessage?.classList.add('hidden');
      promo?.classList.add('hidden');
    } else {
      installmentsMessage?.classList.remove('hidden');

      if (cart.items.some(isMBG)) {
        installmentsMessage?.classList.add('disclaimer');
      } else {
        installmentsMessage?.classList.remove('disclaimer');
      }

      if (installmentsMessage) {
        installmentsMessage.dataset.estimatedTotal = cart.total_price.toString();
      }

      promo?.classList.remove('hidden');
    }

    const estTotal = this.shadowRoot.querySelector<HTMLElement>('#estimated-total');
    estTotal.dataset.cents = cart.total_price.toString();
    estTotal.dataset.currencyCode = this.dataset.currencyCode;
    estTotal.dataset.currencySymbol = this.dataset.currencySymbol;

    this.hideShroud();
  }

  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    const cart = event.detail.cart;

    const heading = this.shadowRoot.querySelector('h2');
    const quantity = cart.item_count;
    if (quantity === 0) {
      heading.textContent = 'Shopping Bag';
    } else if (quantity === 1) {
      heading.textContent = 'Shopping Bag (1 item)';
    } else {
      heading.textContent = ['Shopping Bag (', quantity.toString(), ' items)'].join('');
    }

    if (event.detail.is_initial) {
      this.onCartUpdatedInitial(event);
      return;
    }

    // TODO: the following code needs to be re-written to handle the case of replaying events from
    // the queue that happened prior to the most recent initialization. previously this function
    // was an event listener for events that only occurred after the cart initialized. now it
    // is an event listener for both events before and after.

    const cartContents = this.shadowRoot.querySelector('#cart-contents');
    const emptyCart = this.shadowRoot.querySelector('#empty-cart');
    const footerEl = this.shadowRoot.querySelector('.footer');
    const lineItems = this.querySelector('cart-line-items');

    if (cart.items.length > 0) {
      cartContents?.classList.remove('hidden');
      emptyCart?.classList.add('hidden');
      footerEl.classList.remove('hidden');
      lineItems?.setAttribute('role', 'list');
    } else {
      emptyCart?.classList.remove('hidden');
      cartContents?.classList.add('hidden');
      footerEl.classList.add('hidden');
      lineItems?.removeAttribute('role');
    }

    // we will load only one of these, either the free shipping banner or promo goal progress
    const promoGoalEl = this.querySelector('free-shipping-banner') ||
      this.querySelector('promo-goal-progress');

    const installmentPaymentMessageElement = this.querySelector('installment-payment-message');

    if (this.dataset.countryCode === 'US') {
      installmentPaymentMessageElement?.classList.remove('hidden');

      if (cart.items.some(isMBG)) {
        installmentPaymentMessageElement?.classList.add('disclaimer');
      } else {
        installmentPaymentMessageElement?.classList.remove('disclaimer');
      }

      if (installmentPaymentMessageElement) {
        installmentPaymentMessageElement.dataset.estimatedTotal = cart.total_price.toString();
      }

      promoGoalEl?.classList.remove('hidden');
    } else {
      installmentPaymentMessageElement?.classList.add('hidden');
      promoGoalEl?.classList.add('hidden');
    }

    const estTotal = this.shadowRoot.querySelector<HTMLElement>('#estimated-total');
    estTotal.dataset.cents = cart.total_price.toString();
    estTotal.dataset.currencyCode = this.dataset.currencyCode;
    estTotal.dataset.currencySymbol = this.dataset.currencySymbol;
  }

  /**
   * We only start listening for this event from within the connected callback so we know the dom is
   * ready and the shroud element exists.
   *
   * @todo This also used to manage focus. However, the code that was written to manage focus
   * contained so many bugs that we ended up just deciding to nuke it. Eventually we need to
   * reintroduce tabbable focus but in a standard way (e.g. just using tabindex correctly)
   */
  private onCartTransactionStarted(_event: WindowEventMap['cart-transaction-started']) {
    this.showShroud();
  }

  private hideShroud() {
    const shroud = this.querySelector('#shroud');
    shroud?.classList.add('hidden');
  }

  private showShroud() {
    const shroud = this.querySelector('#shroud');
    shroud?.classList.remove('hidden');
  }

  /**
   * We only start listening for this event from within the connected callback so we know the DOM is
   * ready and the shroud element exists.
   */
  private onCartTransactionCompleted(_event: Event) {
    this.hideShroud();
  }
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

function isMBG(item: Item) {
  return item.properties?.['_is_mbg'] === 'true';
}

declare global {
  interface HTMLElementTagNameMap {
    'cart-element': CartElement;
  }
}

if (!customElements.get('cart-element')) {
  customElements.define('cart-element', CartElement);
}
