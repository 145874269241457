import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * This custom element wraps and displays a pre-checkout upsell drawer when a user clicks "Checkout"
 * from the cart drawer view.
 */
export class UpsellModal extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-show'
    ];
  }

  readonly dataset!: {
    /**
     * If 'true' the opening and closing of the modal will be animated
     */
    animate: 'false' | 'true';

    /**
     * Currency ISO code. Required.
     *
     * @example "US"
     */
    currencyCode: string;

    /**
     * Currency Symbol. Required.
     *
     * @example "US"
     */
    currencySymbol: string;

    /**
     * Controls the visibility of the modal
     */
    show: 'false' | 'true';

    /**
     * Subtitle copy to display in pre-checkout upsell modal
     */
    subtitle: string;

    /**
     * Title copy to display in pre-checkout upsell modal
     */
    title: string;

  };

  private cartVariantIds: number[];
  private onCartUpdatedBound = this.onCartUpdated.bind(this);
  private onExpandAnimationEndedBound = this.onExpandAnimationEnded.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open', delegatesFocus: true });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (this.isConnected && newValue !== oldValue) {
      if (name === 'data-show' && newValue === 'true') {
        this.openModal();
      } else if (newValue === 'false' && oldValue === 'true') {
        this.closeModal();
      }
    }
  }

  public connectedCallback() {
    const closeButton = this.shadowRoot.querySelector<HTMLButtonElement>('.close');
    closeButton.addEventListener('click', onCloseClicked);

    for (const event of cart_drawer_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);
  }

  public disconnectedCallback() {
    const closeButton = this.shadowRoot.querySelector<HTMLButtonElement>('.close');
    closeButton?.removeEventListener('click', onCloseClicked);

    removeEventListener('cart-updated', this.onCartUpdatedBound);
  }

  private openModal() {
    const modal = this.shadowRoot.querySelector<HTMLElement>('div[role="dialog"]');
    modal.removeAttribute('inert');

    const animate = this.dataset.animate ?? 'true';

    if (animate === 'true') {
      // before animation focus cannot be set because the element has `visibility: hidden`, we need
      // to wait till the animation end
      modal.addEventListener('animationend', this.onExpandAnimationEndedBound);
    }

    modal.classList.remove('hidden');
    modal.classList.remove('collapsed');
    modal.classList.add('expanded');

    if (animate === 'false') {
      this.initializeFocus();
    }

    type Detail = WindowEventMap['modal-show-requested']['detail'];
    const modalShowEvent = new CustomEvent<Detail>('modal-show-requested',
      { detail: { element: modal } }
    );
    dispatchEvent(modalShowEvent);
  }

  private closeModal() {
    const modal = this.shadowRoot.querySelector<HTMLElement>('div[role="dialog"]');
    modal.classList.remove('expanded');
    modal.classList.add('collapsed');
    modal.classList.add('hidden');
    modal.setAttribute('inert', '');

    type Detail = WindowEventMap['modal-close-requested']['detail'];
    const modalCloseEvent = new CustomEvent<Detail>('modal-close-requested',
      { detail: { element: modal } }
    );
    dispatchEvent(modalCloseEvent);
  }

  private render() {
    const titleEl = this.shadowRoot.querySelector<HTMLElement>('.header h2');
    titleEl.textContent = this.dataset.title;

    const subtitleEl = this.shadowRoot.querySelector<HTMLElement>('.header p');
    subtitleEl.textContent = this.dataset.subtitle;

    if (this.cartVariantIds?.length < 1) {
      return;
    }

    const upsellItems = this.querySelectorAll<HTMLElement>('li[data-product-id]');
    for (const upsellItem of upsellItems) {
      const variantId = parseInt(upsellItem.dataset.variantId, 10);
      if (this.cartVariantIds.includes(variantId)) {
        upsellItem.setAttribute('hidden', 'true');
      } else {
        upsellItem.removeAttribute('hidden');
      }
    }
  }

  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    this.cartVariantIds = event.detail.cart.items.map(item => item.variant_id);

    this.render();
  }

  private onExpandAnimationEnded(event: AnimationEvent) {
    const modal = <HTMLElement>event.currentTarget;
    modal.removeEventListener('animationend', this.onExpandAnimationEndedBound);

    this.initializeFocus();
  }

  private initializeFocus() {
    const closeButton = this.shadowRoot.querySelector<HTMLButtonElement>('.close');
    closeButton.focus();
  }
}

function onCloseClicked(_event: Event) {
  dispatchEvent(new Event('upsell-close-requested'));

  type Detail = WindowEventMap['cart-open-requested']['detail'];
  const cartOpenEvent = new CustomEvent<Detail>('cart-open-requested',
    { detail: { animate: false } }
  );
  dispatchEvent(cartOpenEvent);
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

declare global {
  interface HTMLElementTagNameMap {
    'upsell-modal': UpsellModal;
  }
}

if (!customElements.get('upsell-modal')) {
  customElements.define('upsell-modal', UpsellModal);
}
