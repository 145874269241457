import type { CustomElement } from '@integrabeauty/custom-elements';
import type { Item } from '@integrabeauty/shopify-ajax-api';
import html from './index.html';

/**
 * This is a container element for cart-line-item elements. This is responsible for rendering the
 * items whenever cart state changes.
 *
 * This does not do partial renders specific to the type of cart update. This always fully
 * re-renders after an update. Any cart update that is in response to some action (e.g. add item,
 * remove item) can contain any kind of state change. Each action such as add to cart obtains a new
 * cart state where multiple things, things not directly related to the items involved, may have
 * changed. For example, removing a line item can change the key property of other items not
 * removed. In addition, there is no pressing need to partially render because the number of items
 * is generally small whereas the amount of engineering effort to properly partially render is huge.
 *
 * Prior implementations of this logic were horrifically flawed. Please never reintroduce support
 * for, or the concept of, partial rendering.
 *
 * @todo this element should not have been implemented, we should be able to just add line item
 * elements as children of the lange cart element and manage cart line items in the lange cart
 * element
 *
 * @deprecated see todo, this element should not have been implemented
 */
class CartLineItems extends HTMLElement implements CustomElement {
  readonly dataset!: {
    /**
     * Country ISO code. Required.
     *
     * @example "US"
     */
    countryCode: string;

    /**
     * Currency ISO Code. Required
     */
    currencyCode: string;

    /**
     * Currency Symbol. Required
     */
    currencySymbol: string;
  };

  private onCartUpdatedBound = this.onCartUpdated.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = html;
  }

  public connectedCallback() {
    for (const event of cart_drawer_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);
  }

  public disconnectedCallback() {
    removeEventListener('cart-updated', this.onCartUpdatedBound);
  }

  /**
   * Any cart update can cause many changes to the cart, including changes from other tabs. We
   * cannot rely on line key changes because unrelated keys can change per mutation. Refresh all
   * line items.
   */
  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    this.render(event.detail.cart.items);
  }

  private render(parents: Item[]) {
    const fragment = document.createDocumentFragment();
    for (const item of parents) {
      const element = this.createItemElement(item);
      fragment.appendChild(element);
    }

    this.innerHTML = '';
    this.appendChild(fragment);
  }

  private createItemElement(item: Item) {
    const lineItemEl = document.createElement('cart-line-item');
    lineItemEl.setAttribute('role', 'listitem');
    lineItemEl.dataset.countryCode = this.dataset.countryCode;
    lineItemEl.dataset.currencyCode = this.dataset.currencyCode;
    lineItemEl.dataset.currencySymbol = this.dataset.currencySymbol;
    lineItemEl.dataset.imageSrc = item.image;
    lineItemEl.dataset.imageAlt = item.title;
    lineItemEl.dataset.isAvailable = 'true';
    lineItemEl.dataset.lineItemId = item.key;
    lineItemEl.dataset.variantId = item.variant_id.toString();
    lineItemEl.dataset.title = item.title;
    lineItemEl.dataset.quantity = item.quantity.toString();
    lineItemEl.dataset.productType = item.product_type;
    lineItemEl.dataset.productUrl = item.url;
    lineItemEl.dataset.price = item.final_price.toString();
    lineItemEl.dataset.linePrice = item.original_line_price.toString();
    lineItemEl.dataset.linePriceAfterDiscount = item.final_line_price.toString();

    if (item.properties._is_upsell === 'true') {
      lineItemEl.dataset.isUpsell = 'true';
      lineItemEl.dataset.maxQuantity = '1';
    }

    if (item.handle) {
      lineItemEl.dataset.productHandle = item.handle;
    }

    if (item.product_id) {
      lineItemEl.dataset.productId = item.product_id.toString();
    }

    if (item.properties.selling_plan) {
      lineItemEl.dataset.sellingPlanId = item.properties.selling_plan;
    }

    const messages = generateMessages(item, this.dataset.currencySymbol);

    if (messages.length > 0) {
      const ulTemplate = this.shadowRoot.querySelector<HTMLTemplateElement>('#message-list');
      const ulClone = <HTMLElement>ulTemplate.content.cloneNode(true);
      const ul = ulClone.querySelector('ul');

      for (const message of messages) {
        const itemTemplate = this.shadowRoot.querySelector<HTMLTemplateElement>('#message');
        const itemClone = <HTMLElement>itemTemplate.content.cloneNode(true);
        const item = itemClone.querySelector('li');
        item.classList.add(message.type);

        const itemMessage = item.querySelector<HTMLSpanElement>('.message');
        itemMessage.innerHTML = message.message;
        ul.appendChild(item);
      }

      lineItemEl.appendChild(ul);
    }

    lineItemEl.dataset.update = Date.now().toString();

    return lineItemEl;
  }
}

interface LineItemMessage {
  message: string;
  type: 'discount' | 'information' | 'warning';
}

/**
 * Builds the messages to show for a line item.
 */
function generateMessages(item: Item, currencySymbol: string) {
  const messages: LineItemMessage[] = [];

  // Render the discounts allocated to the line. It is possible for allocations to allocate 0
  // dollars of a discount application to a line. This is pretty common with discounts such as BXGY
  // discounts that do not reduce the price of the items used in the BX part of the discount, only
  // the GY part. The discounts are allocated to the line as $0 off, as the lines participate in the
  // discount but are not discounted themselves. We only want to render reductions.

  for (const allocation of item.line_level_discount_allocations) {
    if (allocation.discount_application.title && allocation.amount > 0) {
      // The allocation amount is in subunits (e.g. cents in USD), so we divide by 100.
      // We use toFixed to ensure we show 2 significant digits
      const value = (allocation.amount / 100).toFixed(2);

      messages.push({ type: 'discount',
        message: `${allocation.discount_application.title} (${currencySymbol}${value})` });
    } else if (allocation.amount === 0 && /bogo/i.test(allocation.discount_application.title)) {
      const code = allocation.discount_application.title;

      let message: string;
      if (item.quantity === 1) {
        message = `This product qualifies another product for ${code}`;
      } else {
        message = `This product qualifies ${item.quantity} other products for ${code}`;
      }

      messages.push({ type: 'information', message });
    }
  }

  if (item.properties._contains_alcohol === 'true') {
    messages.push({ type: 'information',
      message: 'This item may only ship to the contiguous U.S and Canada.' });
  }

  if (item.properties._compatibility === 'soleil') {
    messages.push({ type: 'warning',
      message: 'Only compatible with <a href="/products/soleil">Soleil</a>' });
  }

  if (item.properties._compatibility === 'max-high-volume') {
    messages.push({ type: 'warning',
      message: 'TOOL SOLD SEPARATELY. Requires <a href="/products/multi-volume-series">' +
        'Multi-Volume Series</a> brush dryer handle to work' });
  }

  if (item.properties._final_sale === 'true') {
    messages.push({ type: 'warning', message: 'This item is final sale, not eligible for return' });
  }

  if (item.properties._backorder_message) {
    messages.push({ type: 'information', message: item.properties._backorder_message });
  }

  return messages;
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    'cart-line-items': CartLineItems;
  }
}

if (!customElements.get('cart-line-items')) {
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  customElements.define('cart-line-items', CartLineItems);
}
