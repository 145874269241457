import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';
import { removeDiscount } from './remove-discount.js';

class DiscountBadge extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-cta-disabled'
    ];
  }

  readonly dataset!: {
    /**
     * Discount code
     *
     * @example "BADGE15"
     */
    code: string;

    /**
     * If true the button removing the discount will be disabled
     */
    ctaDisabled: 'false' | 'true';

    /**
     * Used only if value is provided
     *
     * @example "$"
     */
    currencySymbol: string;

    /**
     * Whether to show a button that will remove the discount code from the cart
     *
     * @default "false"
     */
    showRemoveCta: 'false' | 'true';

    /**
     * There are two styles of the component:
     * - large: visually wrapped in a "chip"
     * - small: doesn't differ a lot visually from a standard text
     */
    size: 'large' | 'small';

    /**
     * Value of the discount as a decimal number, if not provided the value and the currency
     * symbol won't be displayed
     *
     * @example "13.35"
     */
    value: string;
  };

  public shadowRoot!: ShadowRoot;
  private onRemoveDiscountClickedBound = this.onRemoveDiscountClicked.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const remove = this.shadowRoot.querySelector<HTMLElement>('#remove-discount');
    remove.addEventListener('click', this.onRemoveDiscountClickedBound);

    this.render();
  }

  public disconnectedCallback() {
    const remove = this.shadowRoot.querySelector<HTMLElement>('#remove-discount');
    remove?.removeEventListener('click', this.onRemoveDiscountClickedBound);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    const code = this.shadowRoot.querySelector('.code');
    code.textContent = this.dataset.code;

    const value = this.shadowRoot.querySelector<HTMLElement>('.value');
    const currencySymbol = this.shadowRoot.querySelector<HTMLElement>('.currency-symbol');
    if (this.dataset.value) {
      currencySymbol.textContent = this.dataset.currencySymbol;
      currencySymbol.classList.remove('hidden');
      value.textContent = this.dataset.value;
      value.classList.remove('hidden');
    } else {
      value.classList.add('hidden');
      currencySymbol.classList.add('hidden');
    }

    const remove = this.shadowRoot.querySelector<HTMLButtonElement>('#remove-discount');
    if (this.dataset.showRemoveCta === 'true') {
      remove.classList.remove('hidden');
    } else {
      remove.classList.add('hidden');
    }

    remove.disabled = this.dataset.ctaDisabled === 'true';
  }

  private onRemoveDiscountClicked(event: MouseEvent) {
    const button = <HTMLButtonElement>event.target;
    if (!button.disabled) {
      button.disabled = true;
      removeDiscount(this.dataset.code).catch(console.warn);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'discount-badge': DiscountBadge;
  }
}

if (!customElements.get('discount-badge')) {
  customElements.define('discount-badge', DiscountBadge);
}
