import * as ajax from '@integrabeauty/shopify-ajax-api';
import { hasSimilarItems } from '../../../lib/cart-equality.js';
import * as CartState from '../../../lib/cart-state.js';
import { Transaction } from '../../../lib/cart-transaction.js';
import { retry } from '../../../lib/promise-retry.js';

/**
 * Update the cart's line item quantities, note, or attributes.
 *
 * @param input cart update input
 * @param scenario optional description or code describing the context in which this takes place
 *
 * @see https://shopify.dev/docs/api/ajax/reference/cart#post-locale-cart-update-js
 */
export async function updateCart(input: ajax.CartUpdateInput, scenario?: string) {
  const transaction = new Transaction('updateCart', input);
  try {
    await updateCartInternal(transaction, input, scenario);
  } catch (error) {
    type Detail = WindowEventMap['cart-update-erred']['detail'];
    const event = new CustomEvent<Detail>('cart-update-erred', {
      detail: {
        code: determineErrorCode(error),
        error,
        inputs: {
          ...input
        },
        transaction
      }
    });
    dispatchEvent(event);
  } finally {
    transaction.complete();
  }
}

async function updateCartInternal(transaction: Transaction, input: ajax.CartUpdateInput,
  scenario?: string) {
  // It is possible that the client side cart state is stale, so we need to grab it in order to
  // compare it to the remote state.
  const previous = CartState.read();
  // Grab the remote state
  const remoteCart = await retry(ajax.cartFetch(), 3);

  if (!hasSimilarItems(previous, remoteCart)) {
    // Update local state to reflect the latest remote state.
    CartState.write(remoteCart, transaction, scenario);

    // Define a custom error code when throwing this error so that calling code can extract a more
    // specific error code.

    const error: any = new Error('Cart state out of sync');
    error.error_code = 'UPDATE_ITEMS_DESYNC';
    throw error;
  }

  await ajax.cartUpdate(input);
  const cart = await retry(ajax.cartFetch(), 3);
  CartState.write(cart, transaction, scenario);
}

function determineErrorCode(error: any) {
  if (ajax.isUnavailableError(error)) {
    return 'UPDATE_CART_OOS';
  }

  if ('error_code' in error) {
    return error.error_code;
  }

  return 'UPDATE_CART';
}
