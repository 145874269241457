import type { CustomElement } from '@integrabeauty/custom-elements';
import type { Cart } from '@integrabeauty/shopify-ajax-api';
import * as uuid from 'uuid';
import * as CartState from '../../../lib/cart-state.js';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders a "Continue to Checkout" button and a "Continue Shopping" button that closes the side
 * cart.
 *
 * @todo Move this functionality back into the car-element custom element.
 */
class CartActions extends HTMLElement implements CustomElement {
  readonly dataset!: {
    /**
     * Boolean represented as string to indicate if the close button should be hidden
     */
    hideCloseButton: string;

    /**
     * Boolean represented as string to indicate if query parameter to skip shop pay when navigating
     * to checkout should be set to true.
     */
    skipShopPay: 'false' | 'true';
  };

  public shadowRoot!: ShadowRoot;
  private checkoutInitSucceeded = false;
  private onCheckoutButtonClickedBound = this.onCheckoutButtonClicked.bind(this);
  private onCartTransactionStartedBound = this.onCartTransactionStarted.bind(this);
  private onCartTransactionCompletedBound = this.onCartTransactionCompleted.bind(this);
  private onCartUpdatedBound = this.onCartUpdated.bind(this);
  private onDOMContentLoadedBound = this.onDOMContentLoaded.bind(this);
  private onPageShowBound = this.onPageShow.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      setTimeout(this.onDOMContentLoadedBound);
    } else {
      addEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
    }

    addEventListener('pageshow', this.onPageShowBound);
  }

  public disconnectedCallback() {
    const checkoutButton = this.shadowRoot.querySelector<HTMLElement>('#checkout-button');
    checkoutButton?.removeEventListener('click', this.onCheckoutButtonClickedBound);

    const continueShopping = this.shadowRoot.querySelector<HTMLButtonElement>('#continue-shopping');
    continueShopping?.removeEventListener('click', onContinueShoppingButtonClicked);

    removeEventListener('cart-updated', this.onCartUpdatedBound);
    removeEventListener('cart-transaction-started', this.onCartTransactionStartedBound);
    removeEventListener('cart-transaction-completed', this.onCartTransactionCompletedBound);
    removeEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
    removeEventListener('pageshow', this.onPageShowBound);
  }

  private onDOMContentLoaded(_event: Event) {
    const checkoutButton = this.shadowRoot.querySelector<HTMLElement>('#checkout-button');
    checkoutButton.addEventListener('click', this.onCheckoutButtonClickedBound);

    const continueButton = this.shadowRoot.querySelector<HTMLButtonElement>('#continue-shopping');
    if (this.dataset.hideCloseButton === 'true') {
      continueButton.remove();
    } else {
      continueButton.addEventListener('click', onContinueShoppingButtonClicked);
    }

    addEventListener('cart-transaction-started', this.onCartTransactionStartedBound);
    addEventListener('cart-transaction-completed', this.onCartTransactionCompletedBound);

    for (const event of cart_drawer_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);
  }

  /**
   * Handle a click on the continue to checkout button. It turns out that this is a little more
   * complicated than it seems like it should be at first glance because we have some complicated
   * race conditions and sad paths to handle.
   *
   * One issue is that the navigation sometimes takes a while. The current page remains visible
   * sometimes right up until a large amount of the next page is loaded. Since the checkout page can
   * take a terribly long time to load (e.g. 10+ seconds), it gives the user the impression they did
   * not click on the button at all or that there was some error, and so they naturally click again.
   * So another issue is that we have to carefully handle multiple clicks.
   *
   * Handling multiple clicks is also important because of what happens to the checkout cache during
   * navigation. Users can edit the checkout on the checkout page. However, we do not keep the cache
   * up to date on the checkout page. Therefore, the checkout service clears the cached checkout
   * before redirecting. This causes an error if the user manages to click on the continue to
   * checkout button more than once, after the cache has been cleared, but before the new page has
   * loaded. We have to prevent those additional clicks from trying to trigger the redirect again
   * because it leads to an error where the checkout is not found in the cache.
   *
   * Another subtle issue here is that disabling the button does not actually make it so that the
   * button cannot be clicked or that its click handler is not invoked. So we have to actually check
   * for whether the button is disabled. This is because this is a button element that is not a part
   * of a form.
   *
   * We only need to re-enable the button on error. On successful redirection we can leave the
   * button state as disabled up until the browser changes the page.
   *
   * We do not have to log errors to Sentry here. Error handling and console logging is done by the
   * checkout service.
   *
   * @see https://stackoverflow.com/questions/37109771
   */
  private onCheckoutButtonClicked(event: MouseEvent) {
    console.log('checkout button clicked');

    const button = <HTMLButtonElement>event.currentTarget;

    // The button variable should always be defined, but this is in the critical path, so this is
    // extra paranoia.

    if (button?.disabled) {
      console.log('ignoring checkout button click because button is disabled');
      return;
    }

    try {
      sessionStorage.setItem('checkout_button_clicked_epoch', Date.now().toString());
    } catch (error) {
      console.warn(error);
    }

    const modal = document.querySelector('upsell-modal');
    if (modal) {
      // query all upsells that are not hidden
      const activeUpsells = modal.querySelectorAll<HTMLElement>('[data-product-id]:not([hidden])');
      if (activeUpsells.length > 0 && !cart_drawer_upsell_modal_displayed) {
        type Detail = WindowEventMap['cart-close-requested']['detail'];
        const cartCloseEvent = new CustomEvent<Detail>('cart-close-requested',
          { detail: { animate: false } }
        );
        dispatchEvent(cartCloseEvent);

        dispatchEvent(new Event('upsell-open-requested'));
        cart_drawer_upsell_modal_displayed = true;
        return;
      } else {
        console.log('not showing upsell modal on checkout button click');
      }
    } else {
      console.log('element upsell-modal not found on checkout button click');
    }

    // Disable the checkout button. This does not actually prevent it from being clicked because
    // the button is located outside of a form element. However, it does cause future click events
    // to be handled by the click listener differently. Disabling the button before the redirect is
    // important because there is sometimes a substantial visual lag that occurs during the redirect
    // where we have observed visitors try to click the button multiple times while waiting.
    button.disabled = true;

    const skipShopPay = this.dataset.skipShopPay === 'true';
    redirectToCheckoutUrl(skipShopPay);
  }

  /**
   * We only start listening for this event from within the connected callback so we know the dom is
   * ready and the shroud element exists.
   *
   * @todo This also used to manage focus. However, the code that was written to manage focus
   * contained so many bugs that we ended up just deciding to nuke it. Eventually we need to
   * reintroduce tabbable focus but in a standard way (e.g. just using tabindex correctly)
   */
  private onCartTransactionStarted(_event: Event) {
    const button = this.shadowRoot.querySelector<HTMLButtonElement>('#checkout-button');
    button.disabled = true;
  }

  private onCartTransactionCompleted(_event: WindowEventMap['cart-transaction-completed']) {
    if (this.checkoutInitSucceeded) {
      this.endLoading();
    }
  }

  /**
   * Enable the button and hide the spinner.
   */
  private endLoading() {
    const button = this.shadowRoot.querySelector<HTMLButtonElement>('#checkout-button');
    button.disabled = false;
  }

  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    if (event.detail.is_initial) {
      this.checkoutInitSucceeded = true;
    }

    // Only enable the button if we previously observed a successful checkout-initialized event.
    if (this.checkoutInitSucceeded) {
      const button = this.shadowRoot.querySelector<HTMLButtonElement>('#checkout-button');
      button.disabled = false;
    }
  }

  private onPageShow(event: PageTransitionEvent) {
    if (event.persisted) {
      this.endLoading();
    }
  }
}

function onContinueShoppingButtonClicked(_event: MouseEvent) {
  const upsell = document.querySelector('upsell-modal');
  if (upsell?.dataset.show === 'true') {
    dispatchEvent(new Event('upsell-close-requested'));

    type Detail = WindowEventMap['cart-open-requested']['detail'];
    const cartOpenEvent = new CustomEvent<Detail>('cart-open-requested',
      { detail: { animate: false } }
    );
    dispatchEvent(cartOpenEvent);
  } else {
    dispatchEvent(new Event('cart-close-requested'));
  }
}

/**
 * Navigate to checkout.
 *
 * Checkout can occur on other websites such as Shopify's Shopify Pay checkout. We do not have a
 * trivial way of recording the start of checkout when a checkout page loads. Therefore we have to
 * try to fire the event before navigating to checkout.
 *
 * The event is dispatched via setTimeout. Non-native events are evaluated synchronously by
 * listeners. Dispatching via setTimeout ensures that no longer running listener introduces extra
 * delay before the redirect occurs. It is vital that the redirect occurs quickly. There is already
 * a concerning lag that happens when navigating to checkout that we do not want to increase.
 *
 * This is infallible (guaranteed not to throw).
 *
 * @todo we should refactor, this should not be a helper method to the cart-element custom element,
 * instead we should be observing when someone clicks on the button independently of the element,
 * this way we are not coupling a library into the internal logic of the custom element, so that the
 * custom element's internal logic is independent of any library. refactor this and cart element.
 * remove the call to this function in cart-element and have it just be a redirect/form-submit. then
 * implement an observer that listens for clicks and does this click handling logic in parallel as
 * a best effort. since we will be listening in parallel then we will no longer have concerns about
 * delaying or preventing the redirect
 *
 * @todo migrate over to web pixel implementation once Shopify correctly fires checkout started
 * events for those visitors who are navigated directly to shop.pay
 */
function redirectToCheckoutUrl(skipShopPay: boolean) {
  console.log('redirecting to checkout');

  try {
    const cart = CartState.read();

    // Fork so that delays here do not delay calls to location.href, at the expensive of possibly
    // not capturing some events.
    setTimeout(() => {
      type Detail = CheckoutStartedEvent['detail'];
      const event = new CustomEvent<Detail>('checkout-started', {
        cancelable: false,
        detail: {
          cart,
          event_id: uuid.v4()
        }
      });

      dispatchEvent(event);
    }, 0);
  } catch (error) {
    console.warn(error);
  }

  // This uses a relative url so as to work on multiple domains
  const url = new URL(Shopify.routes.root + 'checkout', 'https://langehair.com');
  if (skipShopPay) {
    url.searchParams.set('skip_shop_pay', 'true');
  }

  location.href = url.toString();
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

type CheckoutStartedEvent = CustomEvent<{
  cart: Cart;
  event_id: string;
}>;

declare global {
  interface HTMLElementTagNameMap {
    'cart-actions': CartActions;
  }

  interface WindowEventMap {
    'checkout-started': CheckoutStartedEvent;
  }
}

if (!customElements.get('cart-actions')) {
  customElements.define('cart-actions', CartActions);
}
