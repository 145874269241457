import type { CustomElement } from '@integrabeauty/custom-elements';
import * as ShopifyThemeCurrency from '@shopify/theme-currency';
import styles from './index.scss';

/**
 * This element is responsible for showing a free shipping message. It reacts to a subtotal updated
 * event to calculate if the cart qualifies for free shipping. It relies on a threshold attribute
 * to determine qualification.
 */
class FreeShippingBanner extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return ['data-estimated-total', 'data-threshold'];
  }

  readonly dataset!: {
    /**
     * Estimated customer cart value, exact value is calculated on checkout shipping step
     * (in cents).
     */
    estimatedTotal: string;

    /**
     * Minimum cart value for free shipping (in cents).
     */
    threshold: string;
  };

  public shadowRoot!: ShadowRoot;
  private onCartUpdatedBound = this.onCartUpdated.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style><span></span>`;
  }

  public connectedCallback() {
    for (const event of cart_drawer_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);

    this.render();
  }

  public disconnectedCallback() {
    removeEventListener('cart-updated', this.onCartUpdatedBound);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    this.dataset.estimatedTotal = event.detail.cart.total_price.toString();
  }

  private render() {
    const span = this.shadowRoot.querySelector('span');
    if (span) {
      const total = parseInt(this.dataset.estimatedTotal, 10);
      const threshold = parseInt(this.dataset.threshold, 10);

      const difference = threshold - total;
      if (difference > 0) {
        // eslint-disable-next-line no-template-curly-in-string
        span.textContent = 'Add ' + ShopifyThemeCurrency.formatMoney(difference, '${{amount}}') +
        ' to unlock Free Contiguous US Shipping!';
      } else {
        span.textContent = 'Congratulations! You\'ve unlocked Free Contiguous US Shipping!';
      }
    }
  }
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

declare global {
  interface HTMLElementTagNameMap {
    'free-shipping-banner': FreeShippingBanner;
  }
}

if (!customElements.get('free-shipping-banner')) {
  customElements.define('free-shipping-banner', FreeShippingBanner);
}
