import type { CustomElement } from '@integrabeauty/custom-elements';
import type { DiscountCode } from '@integrabeauty/shopify-ajax-api';
import { getDiscountCodes } from '../../../lib/cart-discount.js';
import * as CartState from '../../../lib/cart-state.js';
import { applyDiscountCodes } from './apply-discount-codes.js';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders a text field for someone to type in a discount code and apply it to the cart. This also
 * renders the apply button and some error messages the currently applied code(s) and a way of
 * removing discounts.
 */
class DiscountInput extends HTMLElement implements CustomElement {
  public shadowRoot!: ShadowRoot;

  /**
   * Discount code objects from a cart object's discount_codes property. This includes both
   * applicable and inapplicable codes. This may be null.
   */
  private discountCodes: DiscountCode[];

  /**
   * Number of active transactions. This helps guarding against applying / removing multiple
   * codes at the same time
   */
  private activeTransactions = 0;

  /**
   * Last error message. If not set, the error box won't be displayed
   */
  private errorMessage: string | null = null;

  private onCartTransactionStartedBound = this.onCartTransactionStarted.bind(this);
  private onCartTransactionEndedBound = this.onCartTransactionEnded.bind(this);
  private onCartUpdatedBound = this.onCartUpdated.bind(this);
  private onCartUpdateErredBound = this.onCartUpdateErred.bind(this);

  private onDiscountFormSubmitBound = this.onDiscountFormSubmit.bind(this);
  private onDiscountInputBound = this.onDiscountInput.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    // we might have missed some events while disconnected, so we reset the transactions lock
    this.activeTransactions = 0;
    // and the error message
    this.errorMessage = null;

    const form = this.shadowRoot.getElementById('discount-form');
    form.addEventListener('submit', this.onDiscountFormSubmitBound);

    const inputElement = <HTMLInputElement>this.shadowRoot.getElementById('input');
    inputElement.addEventListener('input', this.onDiscountInputBound);

    for (const event of cart_drawer_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);

    addEventListener('cart-transaction-completed', this.onCartTransactionEndedBound);
    addEventListener('cart-transaction-started', this.onCartTransactionStartedBound);
    addEventListener('cart-update-erred', this.onCartUpdateErredBound);

    this.render();
  }

  public disconnectedCallback() {
    const form = this.shadowRoot.getElementById('discount-form');
    form?.removeEventListener('submit', this.onDiscountFormSubmitBound);

    const inputElement = <HTMLInputElement>this.shadowRoot.getElementById('input');
    inputElement?.removeEventListener('input', this.onDiscountInputBound);

    removeEventListener('cart-updated', this.onCartUpdatedBound);
    removeEventListener('cart-update-erred', this.onCartUpdateErredBound);
    removeEventListener('cart-transaction-completed', this.onCartTransactionEndedBound);
    removeEventListener('cart-transaction-started', this.onCartTransactionStartedBound);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    const appliedDiscountEl = this.shadowRoot.getElementById('applied-discounts');

    // Only show applicable discount codes. This uses the special discount_codes property of the
    // cart, not the set of discount applications at the cart or line level, so as to allow for
    // early-applied shipping discounts and also for early-applied discount codes that may not be
    // applicable yet to exist but in a hidden state.
    const codes = (this.discountCodes || [])
      .filter(code => code.applicable)
      .map(code => code.code)
      .map(code => code.toUpperCase());

    if (codes.length > 0) {
      appliedDiscountEl.classList.remove('hidden');
      const appliedDiscountCodesEl = appliedDiscountEl.querySelector('#applied-discount-codes');
      appliedDiscountCodesEl.innerHTML = '';
      for (const code of codes) {
        const badge = document.createElement('discount-badge');
        badge.dataset.code = code;
        badge.dataset.size = 'large';
        badge.dataset.showRemoveCta = 'true';
        appliedDiscountCodesEl.appendChild(badge);
      }
    } else {
      appliedDiscountEl.classList.add('hidden');
    }

    const discountForm = this.shadowRoot.getElementById('discount-form');
    discountForm.classList.remove('hidden');

    const badges = this.shadowRoot.querySelectorAll<HTMLElementTagNameMap['discount-badge']>(
      'discount-badge');
    for (const badge of badges) {
      if (this.activeTransactions > 0) {
        badge.dataset.ctaDisabled = 'true';
      } else {
        badge.dataset.ctaDisabled = 'false';
      }
    }

    const input = <HTMLInputElement>this.shadowRoot.getElementById('input');
    input.disabled = this.activeTransactions > 0;

    const submitButton = <HTMLButtonElement>this.shadowRoot.getElementById('submit-button');
    submitButton.disabled = input.value.length < 3 || this.activeTransactions > 0;

    const alert = this.shadowRoot.querySelector('alert-element');
    if (this.errorMessage) {
      alert.dataset.alertType = 'danger';
      alert.innerHTML = '';
      const span = document.createElement('span');
      span.textContent = this.errorMessage;
      span.setAttribute('slot', 'alert-element-content');
      alert.appendChild(span);
      alert.classList.remove('hidden');
    } else {
      alert.classList.add('hidden');
    }
  }

  private onCartTransactionStarted(_event: WindowEventMap['cart-transaction-started']) {
    this.activeTransactions += 1;
    this.render();
  }

  private onCartTransactionEnded(_event: WindowEventMap['cart-transaction-completed']) {
    // there is a completed event without a matching started event on page load - ignore it
    if (this.activeTransactions > 0) {
      this.activeTransactions -= 1;
    }

    this.render();
  }

  /**
   * Whenever someone is typing in the input, we have to consider enabling or disabling the apply
   * button.
   */
  private onDiscountInput(_event: Event) {
    this.render();
  }

  private onDiscountFormSubmit(event: SubmitEvent) {
    event.preventDefault();

    const input = <HTMLInputElement>this.shadowRoot.getElementById('input');
    applyDiscountCodes([input.value], 'cart-discount-input')
      .then(() => input.value = '')
      .catch(console.warn).finally(() => {
        this.render();
      });
  }

  private onCartUpdateErred(event: WindowEventMap['cart-update-erred']) {
    // There are possibly several ways in which a cart updates, so there are several causes of a
    // cart-update-erred event. Submitting the form here is not the only reason for an error event.
    // We have to distinguish between other sources of error events and error events caused by this
    // element. We only want to render errors adjacent to the discount input if those errors were
    // the result of attempting to apply a discount via this element's input. We specify a special
    // scenario in the call to apply the discount that flags this particular element as the source
    // of the error. We check for that here and ignore other kinds of discount application errors.
    // Those other errors are handled separately by other listeners listening on the same event,
    // such as the cart-messages element. Those other listeners update the DOM in other places, such
    // as the top of the cart drawer.

    if (event.detail.code !== 'APPLY_DISCOUNTS') {
      return;
    }

    // Checking above is not enough to isolate errors caused by this element. There are other ways
    // in which discounts are applied, such as on page load. So we also want to check the scenario.

    if (event.detail.scenario !== 'cart-discount-input') {
      return;
    }

    // TODO: look into leveraging the error from the cart update erred event instead of crafting
    // our own message

    // Try to get the existing codes

    let existingCodes: string[];
    try {
      if (isFullyDefinedStringArray(event.detail.inputs?.before_codes) &&
        event.detail.inputs.before_codes.length > 0) {
        existingCodes = event.detail.inputs.before_codes.map(before => before.toUpperCase());
      } else {
        const cart = CartState.read();
        // this ignores whether a discount code is currently applicable as we are only using this to
        // decide what error message to show
        existingCodes = getDiscountCodes(cart);
      }
    } catch (error) {
      console.warn(error);
    }

    // If there are already some codes, then one of the reasons a discount does not apply is
    // because the existing codes are combinable with the new codes and the existing codes provide
    // a better discount. We try to provide a better error message in this case.
    if (existingCodes?.length > 0) {
      this.errorMessage = 'Oops. It looks like this discount could not be applied. But don\'t ' +
      'worry, you\'re already getting the best deal!';
    } else {
      this.errorMessage = 'There was a problem applying the discount.' +
      ' Please try again in a few moments';
    }

    this.render();
  }

  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    // update the current discount code state both on initial page load and when the cart state
    // changes

    this.discountCodes = event.detail.cart.discount_codes.map(code => ({
      applicable: code.applicable,
      code: code.code.toUpperCase()
    }));

    // On any update, we have a successful update. If there was a prior update error, there may be
    // still an error message. Hide that error message.
    this.errorMessage = null;
    // trigger a render
    this.render();
  }
}

function isFullyDefinedStringArray(value: any): value is string[] {
  return Array.isArray(value) && value.every(element => typeof element === 'string');
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

declare global {
  interface HTMLElementTagNameMap {
    'discount-input': DiscountInput;
  }
}

if (!customElements.get('discount-input')) {
  customElements.define('discount-input', DiscountInput);
}
