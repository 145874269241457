import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';
import { removeItems } from './remove-items.js';
import { updateCart } from './update-cart.js';

/**
 * Renders a cart line item, with the ability for the user to adjust quantities, and remove the
 * item.
 */
class CartLineItem extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-is-available',
      'data-max-quantity',
      'data-line-item-id',
      'data-line-price-after-discount',
      'data-line-price',
      'data-price',
      'data-compare-at-price',
      'data-image-src',
      'data-image-alt',
      'data-title',
      'data-quantity',
      'data-variant-id',
      'data-product-handle',
      'data-product-id',
      'data-product-type',
      'data-update',
      'data-selling-plan-id',
      'data-is-upsell'
    ];
  }

  readonly dataset!: {
    /**
     * Country ISO code. Required.
     *
     * @example "US"
     */
    countryCode: string;

    /**
     * Currency ISO Code. Required
     */
    currencyCode: string;

    /**
     * Currency Symbol. Required
     */
    currencySymbol: string;

    /**
     * Image alternate text.
     */
    imageAlt: string;

    /**
     * Image url.
     */
    imageSrc: string;

    /**
     * Whether the item has inventory that is sellable.
     */
    isAvailable: string;

    /**
     * Whether the item is an upsell item that qualifies for special pricing or logic.
     */
    isUpsell: string;

    /**
     * The id of the item in the cart
     */
    lineItemId: string;

    /**
     * The price before discounts. This could be either the MSRP (the compare at price) or it could
     * be the markdown down (the sales price).
     *
     * This is for the entire line, not per unit, so this takes into account quantity.
     */
    linePrice: string;

    /**
     * This is the price after discounts. This is for the entire line, not per unit, so this also
     * takes into account quantity.
     */
    linePriceAfterDiscount: string;

    /**
     * The maximum quantity of the item that can be added to cart. This controls whether certain
     * features, such as a UI element for incrementing quantity, are enabled. Some items have a
     * limitation that they can only be included once in a cart because they are offered at a
     * reduced price under specific conditions.
     */
    maxQuantity: string;

    /**
     * The price of the item (per unit)
     */
    price: string;

    /**
     * The Shopify url slug of the product in the line item.
     */
    productHandle: string;

    /**
     * The Shopify product id
     */
    productId: string;

    /**
     * The Shopify product type
     *
     * @example "hair-care"
     */
    productType: string;

    /**
     * A url for the PDP of the product.
     */
    productUrl: string;

    /**
     * The number of items
     */
    quantity: string;

    /**
     * If the item has an associated selling plan, e.g. it is a subscription item, then this is the
     * id of the related selling plan that has all of the specifics of the subscription.
     */
    sellingPlanId: string;

    /**
     * The line item display title, which is typically the product title.
     */
    title: string;

    /**
     * Setting this attribute to a new value triggers a render. This exists so that we can trivially
     * change several attributes without triggering a render per attribute change, then set this one
     * attribute to trigger a single render.
     */
    update: string;

    /**
     * The id of the variant represented by the cart line item
     */
    variantId: string;
  };

  public shadowRoot!: ShadowRoot;

  private onCartTransactionStartedBound = this.onCartTransactionStarted.bind(this);
  private onCartTransactionCompletedBound = this.onCartTransactionCompleted.bind(this);

  private onRemoveLineItemClickBound = this.onRemoveLineItemClick.bind(this);
  private onQuantityChangedBound = this.onQuantityChanged.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const removeLineItem = this.shadowRoot.querySelector<HTMLElement>('.remove-line-item');
    removeLineItem.addEventListener('click', this.onRemoveLineItemClickBound);

    const quantity = this.shadowRoot.querySelector<HTMLSelectElement>('.quantity');
    quantity.addEventListener('change', this.onQuantityChangedBound);

    addEventListener('cart-transaction-started', this.onCartTransactionStartedBound);
    addEventListener('cart-transaction-completed', this.onCartTransactionCompletedBound);

    this.render();
  }

  public disconnectedCallback() {
    const removeLineItem = this.shadowRoot.querySelector<HTMLElement>('.remove-line-item');
    removeLineItem?.removeEventListener('click', this.onRemoveLineItemClickBound);

    const quantity = this.shadowRoot.querySelector<HTMLSelectElement>('.quantity');
    quantity.removeEventListener('change', this.onQuantityChangedBound);

    removeEventListener('cart-transaction-started', this.onCartTransactionStartedBound);
    removeEventListener('cart-transaction-completed', this.onCartTransactionCompletedBound);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private onQuantityChanged(event: Event) {
    const select = <HTMLSelectElement>event.target;
    const quantity = parseInt(select.value, 10);

    updateCart({
      updates: {
        [this.dataset.lineItemId]: quantity
      }
    }, 'cart-line-item').catch(console.warn);
  }

  private onRemoveLineItemClick(event: MouseEvent) {
    const button = <HTMLButtonElement>event.target;
    if (button.disabled) {
      return;
    }

    removeItems([this.dataset.lineItemId]).catch(console.warn);
  }

  private onCartTransactionStarted(_event: WindowEventMap['cart-transaction-started']) {
    const actionElements = this.shadowRoot.querySelectorAll<HTMLButtonElement | HTMLSelectElement>(
      '.cart-actions');
    for (const actionElement of actionElements) {
      actionElement.disabled = true;
    }
  }

  private onCartTransactionCompleted(_event: WindowEventMap['cart-transaction-completed']) {
    const actionElements = this.shadowRoot.querySelectorAll<HTMLButtonElement | HTMLSelectElement>(
      '.cart-actions');
    for (const actionElement of actionElements) {
      actionElement.disabled = false;
    }
  }

  private render() {
    const lineItemEl = this.shadowRoot.querySelector<HTMLElement>('div.LineItem');
    lineItemEl.id = this.dataset.variantId;

    const primaryImageElement = this.shadowRoot.getElementById('image');

    if (!primaryImageElement.dataset.src) {
      primaryImageElement.dataset.alt = '';
      primaryImageElement.dataset.src = this.dataset.imageSrc;
    }

    // Possible 'undefined' to be a string type when passed undefined value via data attribute
    if (!this.dataset.imageSrc || this.dataset.imageSrc === 'undefined') {
      console.warn('Product missing image', this.dataset.variantId, this.dataset.title);
    }

    if (this.dataset.productUrl) {
      const featuredImageAnchor = this.shadowRoot.querySelector('clickable-area');
      if (featuredImageAnchor) {
        featuredImageAnchor.dataset.url = this.dataset.productUrl;
      }
    }

    const lineItemTitle = this.shadowRoot.querySelector<HTMLElement>('#title');

    if (this.dataset.productUrl) {
      lineItemTitle.setAttribute('href', this.dataset.productUrl);
    } else {
      lineItemTitle.title = 'This product does not have a page to view';
    }

    // Although we can use textContent to assigned the title text to the anchor element, we
    // intentionally use innerHTML to ensure any HTML entities (i.e. &amp;) are decoded.
    lineItemTitle.innerHTML = this.dataset.title;

    const linePriceAfterDiscountEl = this.shadowRoot.getElementById('line-price-after-discount');
    linePriceAfterDiscountEl.dataset.currencyCode = this.dataset.currencyCode;
    linePriceAfterDiscountEl.dataset.currencySymbol = this.dataset.currencySymbol;
    linePriceAfterDiscountEl.dataset.cents = this.dataset.linePriceAfterDiscount.toString();

    // element with id line-price is the "compare at price"
    const linePriceEl = this.shadowRoot.getElementById('line-price');
    linePriceEl.dataset.currencyCode = this.dataset.currencyCode;
    linePriceEl.dataset.currencySymbol = this.dataset.currencySymbol;
    linePriceEl.dataset.cents = this.dataset.linePrice.toString();

    if (parseInt(this.dataset.linePrice, 10) >
      parseInt(this.dataset.linePriceAfterDiscount, 10)) {
      this.shadowRoot.querySelector('.price').classList.remove('no-discount');
      linePriceEl.dataset.ariaLabelPrefix = 'Regular price:';
      linePriceAfterDiscountEl.dataset.ariaLabelPrefix = 'Sale price:';
    } else {
      this.shadowRoot.querySelector('.price').classList.add('no-discount');
      linePriceEl.dataset.ariaLabelPrefix = '';
      linePriceAfterDiscountEl.dataset.ariaLabelPrefix = 'Price:';
    }

    if (this.dataset.isAvailable === 'false') {
      this.shadowRoot.getElementById('out-of-stock').classList.remove('hidden');
    }

    if (this.dataset.sellingPlanId) {
      this.shadowRoot.getElementById('subscription-item').classList.remove('hidden');
    }

    const removeButton = this.shadowRoot.querySelector<HTMLElement>('button.remove-line-item');
    removeButton?.setAttribute('aria-label', ['remove', this.dataset.title, 'from cart'].join(' '));

    this.renderQuantity();
  }

  private renderQuantity() {
    const quantityElement = this.shadowRoot.querySelector<HTMLSelectElement>('.quantity');

    if (this.dataset.isUpsell === 'true') {
      // Only one item of an upsell product can be added, so no need to render the quantity selector

      quantityElement.classList.add('hidden');
      return;
    }

    if (this.dataset.isAvailable === 'false') {
      quantityElement.disabled = true;
    }

    // Since switching the quantity control to a select element, we should have predefined options
    // in the HTML. For now, we've agreed to let users add up to 5 items, but this is negotiable.
    const defaultMaxQuantity = 10;
    const quantity = parseInt(this.dataset.quantity, 10);

    let maxQuantity = defaultMaxQuantity;
    if (this.dataset.maxQuantity) {
      maxQuantity = parseInt(this.dataset.maxQuantity, 10);
    }

    if (maxQuantity < quantity) {
      maxQuantity = quantity;
    }

    if (maxQuantity !== defaultMaxQuantity) {
      // Currently, we set data-max-quantity to a value of 1 for the cart line item only for upsell
      // products. That means in most cases, we will be rendering the default options, so this
      // check prevents updating the DOM if not needed.

      let optionsHtml = '';
      for (let i = 1; i <= maxQuantity; i++) {
        optionsHtml += `<option value="${i}">${i}</option>`;
      }

      quantityElement.innerHTML = optionsHtml;
    }

    quantityElement.value = this.dataset.quantity;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'cart-line-item': CartLineItem;
  }
}

if (!customElements.get('cart-line-item')) {
  customElements.define('cart-line-item', CartLineItem);
}
