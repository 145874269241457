import * as ajax from '@integrabeauty/shopify-ajax-api';
import { getDiscountCodes } from '../../../lib/cart-discount.js';
import * as CartState from '../../../lib/cart-state.js';
import { Transaction } from '../../../lib/cart-transaction.js';
import { retry } from '../../../lib/promise-retry.js';

/**
 * Remove a specific discount code from the cart.
 *
 * This leverages a special flag configured in the lange store that enables the discount property to
 * be specified in the cart update request body. This special feature is not documented.
 *
 * Shopify does not provide an API for removing a specific code. We figured out that the discount
 * property accepts a comma separated values list of discounts to apply. Specifying the empty string
 * means remove all. In order to remove a specific discount, we specify a csv of all codes other
 * than the specific code to remove, which effectively removes that discount code. If omitting the
 * discount code results in an empty csv string, then effectively all codes are removed.
 */
export async function removeDiscount(code: string, scenario?: string) {
  const transaction = new Transaction('removeDiscount', code, scenario);
  try {
    const previous = CartState.read();

    // When obtaining previous codes, we ignore whether the code is applicable. We want to retain
    // inapplicable codes in the update.

    const previousCodes = getDiscountCodes(previous);

    const newCodes = previousCodes.filter(previousCode => previousCode !== code.toUpperCase());

    // new codes may be empty, in which case discount becomes an empty string upon joining, in
    // which case all discount codes are removed.

    const discount = newCodes.join(',');
    await ajax.cartUpdate({ discount });
    const cart = await retry(ajax.cartFetch(), 3);
    CartState.write(cart, transaction, scenario);
  } catch (error) {
    type Detail = WindowEventMap['cart-update-erred']['detail'];
    const event = new CustomEvent<Detail>('cart-update-erred', {
      detail: {
        code: 'REMOVE_DISCOUNT',
        error,
        transaction
      }
    });
    dispatchEvent(event);
  } finally {
    transaction.complete();
  }
}
