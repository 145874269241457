import { Cart } from '@integrabeauty/shopify-ajax-api';
import { applyDiscountsHelper } from '../../../lib/cart-apply-discounts-helper.js';
import { getApplicableDiscountCodes } from '../../../lib/cart-discount.js';
import * as CartState from '../../../lib/cart-state.js';
import { Transaction } from '../../../lib/cart-transaction.js';

/**
 * Applies discount codes to the cart.
 *
 * Do not include existing codes in new codes. Only include new codes in new codes.
 *
 * This does not check whether client state is desynced because it does not matter.
 *
 * @todo applyDiscountsHelper can error updating the cart during post validation, we need to reflect
 * that cart state updated but when doing so an error occurred, right now we just emit an error
 * event and do not reflect the change in cart state which is misleading
 */
export async function applyDiscountCodes(newCodes: string[], scenario?: string) {
  const transaction = new Transaction('applyDiscountCodes', newCodes);

  let beforeCart: Cart;

  try {
    beforeCart = CartState.read();
    const afterCart = await applyDiscountsHelper(beforeCart, newCodes, cart_init.discount_overrides,
      true);
    CartState.write(afterCart, transaction, scenario);
  } catch (error) {
    // In this catch clause we must be extra paranoid not to introduce additional errors so we can
    // make very few assumptions about state.

    // Downstream analytics looks at codes that failed to apply. Downstream tools do simple string
    // aggregation. If we use raw codes, which can be in mixed case, we end up with one bucket for
    // each variation of character case. To make things easier we emit normalized values.
    const normalNewCodes = Array.isArray(newCodes) ? newCodes.map(code => code.toUpperCase()) : [];

    type Detail = WindowEventMap['cart-update-erred']['detail'];
    const event = new CustomEvent<Detail>('cart-update-erred', {
      detail: {
        code: 'APPLY_DISCOUNTS',
        error,
        inputs: {
          before_codes: getApplicableDiscountCodes(beforeCart),
          codes: normalNewCodes
        },
        scenario,
        transaction
      }
    });

    dispatchEvent(event);
  } finally {
    transaction.complete();
  }
}
