import { assert } from '@integrabeauty/assert';
import * as ajax from '@integrabeauty/shopify-ajax-api';
import { hasSimilarItems } from '../../../lib/cart-equality.js';
import * as CartState from '../../../lib/cart-state.js';
import { Transaction } from '../../../lib/cart-transaction.js';
import { retry } from '../../../lib/promise-retry.js';

/**
 * Removes items from the cart.
 *
 * Each id in the array can be either a line key or a variant id. A cart can have multiple line
 * items that share the same variant id. For example, when variants have different line item
 * properties, or automatic discounts create variants at different prices. Because of this, it is
 * recommended to use the line item key to ensure that you're only changing the intended line item.
 *
 * If you want to also change other cart properties in the same call, use the more general
 * updateCart call instead while specifying lines with quantity 0 to effectively remove. This
 * removeItems call is just a convenient but limited way of doing a cart update.
 *
 * Even though Shopify permits specifying a variant id as a number, we require that it is a string
 * because we have seen strange, undocumented errors when specifying variant ids as numbers, such
 * as some kind of server side Ruby error with parsing numbers.
 *
 * @see https://shopify.dev/docs/api/ajax/reference/cart#update-line-item-quantities
 */
export async function removeItems(lineKeys: string[], scenario?: string) {
  const transaction = new Transaction('removeItems', lineKeys, scenario);
  let errorCode = 'REMOVE_ITEMS';

  let previous: ajax.Cart;
  let remoteCart: ajax.Cart;

  try {
    assert(lineKeys.length > 0, 'At least one item must be specified');

    // Even though shopify supports variant ids as change item ids as numbers, this function does
    // not support using variant id. This function only operates on line keys, which must always
    // be strings. Even though we have type safety checks, we still want a runtime check, as the
    // calling code is typically less diligent.
    assert(lineKeys.every(isString), 'One or more invalid line keys');

    // Ensure the caller did not provide duplicates
    const uniqueKeySet = new Set(lineKeys);
    assert(uniqueKeySet.size === lineKeys.length, 'One or more duplicate line keys');

    // cart/update supports removing multiple items at once, but cart/change only supports changing
    // a single item at once. While it would be ideal to use cart/update, cart/update has an
    // undocumented bug where attempting to set the quantity of an out of stock line item to 0
    // results in a 422 error. We reached out to Shopify support, and they stated that we should
    // use cart/change instead. So now removing multiple items at once gets complicated, because we
    // have no real transactional safety. In fact, we should probably drop support for removing
    // multiple items at once and fully migrate the use cases for removing multiple items to other
    // solutions, e.g. use the Shopify cart transforms for bulk add/remove/update, such as what the
    // Shopify bundles app does. In the short term we are going to attempt to support bulk removal
    // by sequentially removing items.

    // Removing multiple items at once, or at least attempting to support the invented abstraction
    // of an atomic mutation on the cart that is actually a compound mutation, is further
    // complicated by how cart/change behaves. Another undocumented feature of cart/change is that
    // changing any one item's quantity to 0 can cause other line items to change their keys. The
    // keys of other lines change in an undocumented manner. It is not clear if affecting a single
    // line can change any other line, or only lines that have a later position. I think what is
    // happening is that the keys of later lines shift when the quantity of an earlier line is
    // changed to 0. So, the idea is that if we remove lines in reverse order, we avoid the issue
    // with shifting keys per change.

    // Clarified, removing any one line can impact the keys of all other lines. So we cannot remove
    // by key in a batch, because each single removal affects the rest.

    // The next problem then is that the calling code is not obligated to provide us line keys in
    // natural cart order. So we have to find a way to get the natural order. In which case the
    // next problem is that in order to figure that out we need the current cart state, which is
    // not provided as input. So we have to get the current cart state and assume it is
    // authoritative.

    previous = CartState.read();

    // Verify that the cart state is not stale by fetching the latest server side cart state prior
    // to mutation.

    remoteCart = await retry(ajax.cartFetch(), 3);

    if (!hasSimilarItems(previous, remoteCart)) {
      // Update local state to reflect the latest remote state.
      CartState.write(remoteCart, transaction, scenario);

      errorCode = 'REMOVE_ITEMS_DESYNC';
      throw new Error('Cart state out of sync');
    }

    // Verify that the number of lines to remove is less than or equal to the number of items in
    // the cart. It is possible that the visitor is looking at a stale cart state and then trying
    // to remove one or more items from a stale cart state. One thing we can detect is that when the
    // cart state is stale, there should still always be enough items in the cart to remove. This is
    // somewhat redundant with a later assertion but it still gives us a more exact error.
    assert(lineKeys.length <= previous.items.length, 'Too many line keys');

    const lineIndicesToRemove = [];
    for (let i = 0; i < previous.items.length; i++) {
      const item = previous.items[i];
      if (lineKeys.includes(item.key)) {
        lineIndicesToRemove.push(i + 1);
      }
    }

    assert(lineIndicesToRemove.length === lineKeys.length, 'One or more line keys not found');

    // Reorder from last to first, as each removal changes the position of later ones
    lineIndicesToRemove.reverse();

    // Then perform the removals. We cannot perform the removals concurrently. We have to perform
    // the removals sequentially as racing them concurrently carries the risk that the removals
    // occur in the order we did not intended.

    for (const line of lineIndicesToRemove) {
      await ajax.cartChange({ line, quantity: 0 });
    }

    // Because we intentionally ignore the output of each cart/change, obtain the updated cart state
    // through yet another request.

    const cart = await ajax.cartFetch();
    CartState.write(cart, transaction, scenario);
  } catch (error) {
    type Detail = WindowEventMap['cart-update-erred']['detail'];
    const event = new CustomEvent<Detail>('cart-update-erred', {
      detail: {
        code: errorCode,
        error,
        inputs: {
          ids: lineKeys,
          previous_cart_item_line_keys: previous?.items?.map(item => item?.key),
          before_remove_fetched_cart_state: remoteCart?.items?.map(item => item?.key),
          scenario
        },
        transaction
      }
    });
    dispatchEvent(event);
  } finally {
    transaction.complete();
  }
}

function isString(value: any): value is string {
  return typeof value === 'string';
}
