import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * This element renders an upsell item with the ability for the user to add an upsell to cart.
 */
class CartUpsellItem extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return ['data-variant-id', 'data-product-tags', 'data-compare-at-price'];
  }

  readonly dataset!: {
    /**
     * Compare at price
     */
    compareAtPrice: string;

    /**
     * Country ISO code
     */
    country: string;

    /**
     * Currency ISO code
     *
     * @example "USD"
     */
    currencyCode: string;

    /**
     * Currency symbol character
     *
     * @example "$"
     */
    currencySymbol: string;

    /**
     * Product handle
     */
    handle: string;

    /**
     * Product id
     */
    id: string;

    /**
     * Product image url
     */
    image: string;

    /**
     * Product price
     */
    price: string;

    /**
     * CSV of product tags
     */
    productTags: string;

    productTitle: string;

    /**
     * Average product rating
     */
    reviewsAverage: string;

    /**
     * Number of product reviews
     */
    reviewsCount: string;

    /**
     * Variant SKU
     */
    sku: string;

    templateName: string;
    title: string;

    /**
     * Product type
     */
    type: string;

    variantId: string;

    variantTitle: string;
  };

  public shadowRoot!: ShadowRoot;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    if (!this.dataset.variantId) {
      return;
    }

    // render title
    const upsellLink = new URL(`products/${this.dataset.handle}`, location.origin);
    const titleAnchor = this.shadowRoot.querySelector('.title a');
    titleAnchor?.setAttribute('href', upsellLink.toString());

    let title = this.dataset.productTitle;
    if (this.dataset.variantTitle !== 'Default Title') {
      title += ` ${this.dataset.variantTitle}`;
    }

    titleAnchor.textContent = title;

    // render image
    const image = this.shadowRoot.getElementById('image');
    // the image is decorative (described by the adjacent text)
    image.dataset.alt = '';
    image.dataset.src = this.dataset.image;

    const clickableArea = this.shadowRoot.querySelector('clickable-area');
    clickableArea.dataset.url = upsellLink.toString();

    let price = this.dataset.price;
    let compareAtPrice = this.dataset.compareAtPrice;
    const upsellTagSearch = this.dataset.currencyCode === 'CAD' ? 'UPSELL:CAD' : 'UPSELL';

    // It is important to use optional chaining here because product tags is not guaranteed defined
    const upsellTag = this.dataset.productTags?.split(' ').find(t => t.includes(upsellTagSearch));

    if (upsellTag) {
      const upsellPriceArray = upsellTag.split(':');
      const upsellPriceString = this.dataset.currencyCode === 'CAD' ?
        upsellPriceArray[2] :
        upsellPriceArray[1];
      const upsellPrice = parseFloat(upsellPriceString) * 100;

      // Not all upsell items require their price and compareAtPrice values to be updated before
      // being presented to the user. To prevent over writing already properly priced products,
      // we must confirm the extracted upsell price from the products tags is a lower value. If so
      // we proceed with the updating the compareAtPrice.
      if (upsellPrice < parseInt(price, 10) && parseInt(price, 10) > parseInt(compareAtPrice, 10)) {
        compareAtPrice = price;
      }

      price = upsellPrice.toString();
    }

    const priceEl = this.shadowRoot.getElementById('price');
    priceEl.dataset.currencyCode = this.dataset.currencyCode;
    priceEl.dataset.currencySymbol = this.dataset.currencySymbol;
    priceEl.dataset.cents = price;

    const compareAtPriceEl = this.shadowRoot.getElementById('compare-at-price');
    compareAtPriceEl.dataset.currencyCode = this.dataset.currencyCode;
    compareAtPriceEl.dataset.currencySymbol = this.dataset.currencySymbol;

    if (compareAtPrice) {
      compareAtPriceEl.dataset.cents = compareAtPrice;
      compareAtPriceEl.dataset.hide = 'false';
      compareAtPriceEl.dataset.ariaLabelPrefix = 'Regular price:';
      priceEl.dataset.ariaLabelPrefix = 'Sale price:';
    } else {
      // hide the compare
      compareAtPriceEl.dataset.hide = 'true';
      compareAtPriceEl.dataset.ariaLabelPrefix = '';
      priceEl.dataset.ariaLabelPrefix = 'Price:';
    }

    const customAttribute = document.createElement('custom-attribute');
    customAttribute.dataset.key = '_is_upsell';
    customAttribute.dataset.value = 'true';

    const variant = document.createElement('variant-element');
    variant.setAttribute('slot', 'add-to-cart-button-data-variant');
    variant.dataset.id = this.dataset.variantId;
    variant.dataset.quantity = '1';
    variant.appendChild(customAttribute);

    const addButton = this.shadowRoot.querySelector('add-to-cart-button');
    addButton.dataset.country = this.dataset.country;
    addButton.dataset.templateName = this.dataset.templateName;
    addButton.dataset.variantTitle = title;

    if (this.shadowRoot.querySelector(`variant-element[data-id="${this.dataset.variantId}"]`)) {
      // do not add
    } else {
      addButton.appendChild(variant);
    }

    addButton.dataset.update = Date.now().toString();

    // render rating stars
    const reviews = this.shadowRoot.querySelector('product-rating-stars');
    reviews.dataset.average = this.dataset.reviewsAverage;
    reviews.dataset.count = this.dataset.reviewsCount;
    reviews.dataset.reviewsUrl = `${upsellLink.toString()}#reviews-section`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'cart-upsell-item': CartUpsellItem;
  }
}

if (!customElements.get('cart-upsell-item')) {
  customElements.define('cart-upsell-item', CartUpsellItem);
}
