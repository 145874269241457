import type { CustomElement } from '@integrabeauty/custom-elements';
import type { ProductVariant } from '../../../lib/cart-events.js';

/**
 * Renders cart messages by building and appending alert-element elements.
 *
 * @todo check liquid request.design_mode and do not render the element instead of always checking
 * in the connected callback
 */
class CartMessages extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return ['data-update'];
  }

  readonly dataset!: {
    /**
     * Setting this attribute triggers rendering.
     */
    update: string;
  };

  public shadowRoot!: ShadowRoot;
  private onCartUpdatedBound = this.onCartUpdated.bind(this);
  private onCartUpdateErredBound = this.onCartUpdateErred.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  public connectedCallback() {
    if (Shopify.designMode) {
      return;
    }

    for (const event of cart_drawer_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      } else if (isCartUpdateErredEvent(event)) {
        try {
          this.onCartUpdateErredBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);
    addEventListener('cart-update-erred', this.onCartUpdateErredBound);
  }

  public disconnectedCallback() {
    removeEventListener('cart-updated', this.onCartUpdatedBound);
    removeEventListener('cart-update-erred', this.onCartUpdateErredBound);
  }

  private onCartUpdateErred(event: WindowEventMap['cart-update-erred']) {
    // Clear any previous messages
    const alerts = this.shadowRoot.querySelectorAll('alert-element');
    for (const alert of alerts) {
      alert.remove();
    }

    switch (event.detail.code) {
      case 'COOKIES_UNAVAILABLE': {
        this.showErrorMessage('This site requires the use of cookies');

        dispatchEvent(new Event('cart-open-requested'));
        break;
      }

      case 'INIT': {
        this.showErrorMessage('Something went wrong obtaining your shopping bag.' +
          ' It is probably temporary. Try refreshing the page.');

        dispatchEvent(new Event('cart-open-requested'));
        break;
      }

      case 'ADD_ITEMS':
        this.showErrorMessage('Oh no! There was a problem adding the items to your bag. ' +
          'Please try again in a few moments.');
        break;
      case 'ADD_ITEMS_OOS':
        this.showErrorMessage('Oh no! There was a problem adding the item(s) to your bag. ' +
          'One or more of the products is out of stock.');
        break;
      case 'REMOVE_ITEMS':
        this.showErrorMessage('Oh no! Something went wrong removing items from your bag! ' +
          'Try refreshing the page and trying again.');
        break;
      case 'REMOVE_ITEMS_DESYNC':
        this.showErrorMessage('Oh no! Something went wrong removing items from your bag! ' +
          'Try refreshing the page and trying again.');
        break;
      case 'UPDATE_ITEMS':
        this.showErrorMessage('Oh no! Something went wrong updating items in your bag! ' +
          'Try refreshing the page and trying again.');
        break;
      case 'UPDATE_ITEMS_DESYNC':
        this.showErrorMessage('Oh no! Something went wrong updating items in your bag! ' +
          'Try refreshing the page and trying again.');
        break;
      case 'UPDATE_ITEMS_OOS':
        this.showErrorMessage('Oh no! Something went wrong updating items in your bag! ' +
          'One or more products are out of stock.');
        break;
      case 'APPLY_DISCOUNTS':
        // If there are existing applied discount codes to the cart and the scenario is
        // "cart-discount-input" we do not display a cart level error message since the
        // <discount-input> custom element has its own messaging logic and will handle these cases.
        if (Array.isArray(event.detail.inputs?.before_codes) &&
          event.detail.inputs?.before_codes.length === 0 &&
          event.detail.scenario !== 'cart-discount-input') {
          this.showErrorMessage('Oh no! Something went wrong applying your discount code.');
        }

        break;
      case 'REMOVE_DISCOUNT':
        this.showErrorMessage('Oh no! Something went wrong removing your discount code.');
        break;
    }
  }

  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    // We do not show change messages for the transition from a null cart to an initial cart state.
    // The deltas are not meaningful.
    if (event.detail.is_initial) {
      return;
    }

    // We generally should not be mutating the dom one node at a time and should use some batch
    // removal technique such as setting the parent element's innerHTML to an empty string. However
    // there are extremely few messages so this is not a concern.

    // Clear any previous messages
    const alerts = this.shadowRoot.querySelectorAll('alert-element');
    for (const alert of alerts) {
      alert.remove();
    }

    // Show added items messages
    if (event.detail.variants_added.length > 0) {
      this.showItemsAddedMessage(event.detail.variants_added);
    }

    // Show removed items messages
    if (event.detail.variants_removed.length > 0) {
      const titles = event.detail.variants_removed.map(variant => variant.product_title);
      const itemsString = titles.join(', ');
      const message = document.createElement('div');
      message.setAttribute('slot', 'alert-element-content');
      message.textContent = `You removed ${itemsString} from your shopping bag.`;
      const alert = document.createElement('alert-element');
      alert.dataset.alertType = 'success';
      alert.appendChild(message);
      this.shadowRoot.appendChild(alert);
    }

    this.showDiscountCodeAppliedMessages(event);
    this.showDiscountRemovedMessages(event);
  }

  /**
   * @todo the event diff logic needs to emit discount applications so that we can grab other
   * properties of each new discount, such as the amount applied
   * @todo calculate actual percentage off to ensure the proper messaging is presented, currently
   * there is basically dead code here because discount percentage is always 0
   */
  private showDiscountCodeAppliedMessages(event: WindowEventMap['cart-updated']) {
    for (const code of event.detail.discount_codes_added) {
      let message = '';
      const discountPercentage = 0.0;
      if (discountPercentage > 0) {
        if (discountPercentage > 15 && discountPercentage < 30) {
          message += `You're saving ${discountPercentage}%! `;
        } else if (discountPercentage >= 30 && discountPercentage < 50) {
          message += `Whoa! ${discountPercentage}% savings on your order! `;
        } else if (discountPercentage >= 50) {
          message += `Extreme savings alert! ${discountPercentage}% discount in your bag! `;
        }
      }

      message += `Applied discount code ${code}.`;
      this.showSuccessMessage(message);
    }
  }

  private showDiscountRemovedMessages(event: WindowEventMap['cart-updated']) {
    for (const code of event.detail.discount_codes_removed) {
      const message = `The discount ${code} was removed.`;
      this.showSuccessMessage(message);
    }
  }

  private showItemsAddedMessage(items: ProductVariant[]) {
    const upsellItem = items.find(item => item.merged_properties._is_upsell);
    if (upsellItem) {
      this.showUpsellItemMessage(upsellItem);
      return;
    }

    const backorderedItem = items.find(item => item.merged_properties._backorder_message);
    if (backorderedItem) {
      this.showBackorderItemMessage(backorderedItem);
    }

    const addedLineItemsString = items.map(item => item.product_title).join(', ');

    const message = document.createElement('div');
    message.setAttribute('slot', 'alert-element-content');
    message.innerHTML = `This is so great! You added ${addedLineItemsString} to your shopping` +
    ' bag!';

    const alert = document.createElement('alert-element');
    alert.dataset.alertType = 'success';
    alert.appendChild(message);
    this.shadowRoot.appendChild(alert);
  }

  private showBackorderItemMessage(item: ProductVariant) {
    let message = item.merged_properties._backorder_message[0];
    if (!message) {
      message =
        'This item is currently on backorder, estimated ship date is visible on product page';
    }

    const backorderMessage = document.createElement('div');
    backorderMessage.setAttribute('slot', 'alert-element-content');
    backorderMessage.textContent = `Important note about: ${item.product_title}. ${message}`;

    const backorderAlert = document.createElement('alert-element');
    backorderAlert.dataset.alertType = 'warning';
    backorderAlert.appendChild(backorderMessage);

    this.shadowRoot.appendChild(backorderAlert);
  }

  private showErrorMessage(messageHtml: string) {
    const message = document.createElement('div');
    message.setAttribute('slot', 'alert-element-content');
    message.textContent = messageHtml;

    const alert = document.createElement('alert-element');
    alert.dataset.alertType = 'danger';
    alert.appendChild(message);

    this.shadowRoot.appendChild(alert);
  }

  private showSuccessMessage(messageHtml: string) {
    const message = document.createElement('div');
    message.setAttribute('slot', 'alert-element-content');
    message.textContent = messageHtml;

    const alert = document.createElement('alert-element');
    alert.dataset.alertType = 'success';
    alert.appendChild(message);

    this.shadowRoot.appendChild(alert);
  }

  private showUpsellItemMessage(item: ProductVariant) {
    const message = document.createElement('div');
    message.setAttribute('slot', 'alert-element-content');
    message.textContent = `${item.product_title} is a special ` +
    'additional savings item. It can only be purchased together with a regular item and only ' +
    'once per order.';

    const alert = document.createElement('alert-element');
    alert.dataset.alertType = 'info';
    alert.appendChild(message);

    this.shadowRoot.appendChild(alert);
  }
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

function isCartUpdateErredEvent(value: any): value is WindowEventMap['cart-update-erred'] {
  return value?.type === 'cart-update-erred';
}

declare global {
  interface HTMLElementTagNameMap {
    'cart-messages': CartMessages;
  }
}

if (!customElements.get('cart-messages')) {
  customElements.define('cart-messages', CartMessages);
}
