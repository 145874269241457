import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * This element is a higher level wrapper to implement the shopping bag drawer. It does not require
 * any knowledge of the underlying cart-element functionality and is only concerned with managing
 * the drawer state.
 */
class CartDrawer extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return ['data-show'];
  }

  readonly dataset!: {
    /**
     * If 'true' the opening and closing of the modal will be animated
     */
    animate: 'false' | 'true';

    /**
     * Whether the cart drawer is open.
     *
     * If the attribute is set to "true" the cart drawer is visible, if the attribute is set to
     * "false", not setting or missing the cart drawer will not be visible.
     */
    show: 'false' | 'true';
  };

  private onCartUpdatedBound = this.onCartUpdated.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const closeButton = this.shadowRoot.querySelector<HTMLElement>('.close-cart-drawer');
    closeButton?.addEventListener('click', onClickToClose);

    for (const event of cart_drawer_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);
  }

  public disconnectedCallback() {
    const closeButton = this.shadowRoot.querySelector<HTMLElement>('.close-cart-drawer');
    closeButton?.removeEventListener('click', onClickToClose);

    removeEventListener('cart-updated', this.onCartUpdatedBound);
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name === 'data-show') {
      if (newValue === 'true' && newValue !== oldValue) {
        // show when newValue is 'true' but it wasn't before
        this.showCartDrawer();
      } else if ((newValue === 'false' || !newValue) && oldValue) {
        // hide when newValue is 'false', empty or null but oldValue was set previously
        this.hideCartDrawer();
      }
    }
  }

  /**
   * If the emitted "cart-updated" event is not the initialization of the cart on page load, we must
   * scroll the cart drawer window to the top to ensure any cart related messaging is brought into
   * view for the customer.
   */
  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    const modal = this.shadowRoot.querySelector<HTMLElement>('div[role="dialog"]');
    if (!event.detail.is_initial) {
      modal.scrollTo(0, 0);
    }
  }

  private showCartDrawer() {
    const modal = this.shadowRoot.querySelector<HTMLElement>('div[role="dialog"]');
    modal.removeAttribute('inert');
    modal.scrollTo(0, 0);
  }

  private hideCartDrawer() {
    const modal = this.shadowRoot.querySelector<HTMLElement>('div[role="dialog"]');
    modal.setAttribute('inert', '');
  }
}

function onClickToClose(_event: MouseEvent) {
  dispatchEvent(new Event('cart-close-requested'));
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

declare global {
  interface HTMLElementTagNameMap {
    'cart-drawer': CartDrawer;
  }
}

if (!customElements.get('cart-drawer')) {
  customElements.define('cart-drawer', CartDrawer);
}
