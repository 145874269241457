import type { CustomElement } from '@integrabeauty/custom-elements';
import type { Cart } from '@integrabeauty/shopify-ajax-api';
import html from './index.html';
import styles from './index.scss';

/**
 * Takes promo-goal web elements as inputs in its slot and renders a progress bar.
 *
 * @todo remove any code setting data-currency-code, data-currency-symbol, data-estimated-total
 */
class PromoGoalProgress extends HTMLElement implements CustomElement {
  readonly dataset!: {
    currencyCode: string;
    currencySymbol: string;
  };

  public shadowRoot!: ShadowRoot;
  private onCartUpdatedBound = this.onCartUpdated.bind(this);
  private cart: Cart;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    for (const event of cart_drawer_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);

    this.render();
  }

  public disconnectedCallback() {
    removeEventListener('cart-updated', this.onCartUpdatedBound);
  }

  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    // this.dataset.estimatedTotal = event.detail.cart.total_price.toString();
    this.cart = event.detail.cart;
    this.render();
  }

  private render() {
    if (!this.cart) {
      return;
    }

    // We want the total after discounts, except that we want to exclude the promotional discount
    // itself. As a result, we cannot work with the original total price or the price after
    // discounts. So we have to create a custom total of discounts and subtract this from the
    // original total price to get a custom price after discounts.

    const originalTotalPrice = this.cart.original_total_price;

    // This total amount of discounts, regardless of the type of discount
    const totalDiscountPrice = calculateTotalDiscount(this.cart);

    // This is the total amount discounted by promo goal discounts (e.g. BMSM)
    const totalPromoGoalDiscountPrice = calculateTotalPromoGoalDiscount(this.cart);

    const totalPriceOfExcludedItems = calculateTotalPriceOfPromoExcludedItems(this.cart);

    const priceAfterDiscounts = originalTotalPrice - totalDiscountPrice;
    const priceAfterDiscountsExceptPromoGoalDiscounts = priceAfterDiscounts +
      totalPromoGoalDiscountPrice - totalPriceOfExcludedItems;

    const paragraph = this.shadowRoot.querySelector('p');

    const promoGoals = this.collectPromoGoalsData();

    if (promoGoals.length === 0) {
      paragraph.classList.add('hidden');
      return;
    }

    let eligibleThreshold = 0;
    const eligiblePromoGoals = [];

    // The goals array is sorted from lowest threshold to highest

    for (const promoGoal of promoGoals) {
      // When the cart subtotal (estimated total) is greater than or equal to the threshold, then
      // the promo goal has already been unlocked and can be skipped.
      if (priceAfterDiscountsExceptPromoGoalDiscounts >= promoGoal.thresholdCents) {
        promoGoal.element.dataset.isLocked = 'false';
        continue;
      }

      promoGoal.element.dataset.isLocked = 'true';

      // If there are no eligible promo goals, add them now
      if (eligiblePromoGoals.length === 0) {
        eligiblePromoGoals.push(promoGoal);
        // also capture any other promo goals with identical threshold
        eligibleThreshold = promoGoal.thresholdCents;
        continue;
      }

      if (eligibleThreshold === promoGoal.thresholdCents) {
        eligiblePromoGoals.push(promoGoal);
        continue;
      }
    }

    if (eligiblePromoGoals.length === 0) {
      paragraph.classList.add('hidden');
    }

    const thresholdAmount = eligiblePromoGoals.length > 0 ?
      eligiblePromoGoals[0].thresholdCents :
      promoGoals[promoGoals.length - 1].thresholdCents;

    const amountToGoal = thresholdAmount - priceAfterDiscountsExceptPromoGoalDiscounts;
    const offerText = eligiblePromoGoals.map(i => i.offerText).join(' and ');

    paragraph.innerHTML = `Add <price-element
      data-currency-code="${this.dataset.currencyCode}"
      data-currency-symbol="${this.dataset.currencySymbol}"
      data-cents="${amountToGoal}">
      </price-element> to unlock ${offerText}!`;

    const percentSpentToGoal = Math.min(100, Math.round(
      (priceAfterDiscountsExceptPromoGoalDiscounts / thresholdAmount) * 100));
    const progressEl = this.shadowRoot.querySelector('#progress');
    progressEl.setAttribute('style', `width: ${percentSpentToGoal}%;`);

    type PriceElement = HTMLElementTagNameMap['price-element'];
    const spentEl = <PriceElement>this.shadowRoot.getElementById('spent');
    spentEl.dataset.currencyCode = this.dataset.currencyCode;
    spentEl.dataset.currencySymbol = this.dataset.currencySymbol;

    if (priceAfterDiscountsExceptPromoGoalDiscounts >=
      promoGoals[promoGoals.length - 1].thresholdCents) {
      spentEl.dataset.cents = '';
    } else {
      spentEl.dataset.cents = priceAfterDiscountsExceptPromoGoalDiscounts.toString();
    }

    const goalEl = <PriceElement>this.shadowRoot.getElementById('goal');
    goalEl.dataset.currencyCode = this.dataset.currencyCode;
    goalEl.dataset.currencySymbol = this.dataset.currencySymbol;
    goalEl.dataset.cents = thresholdAmount.toString();

    if (eligiblePromoGoals.length > 0) {
      paragraph.classList.remove('hidden');
    }

    const progressBar = progressEl.parentElement;
    progressBar.setAttribute('aria-valuenow', percentSpentToGoal.toString());
    if (percentSpentToGoal < 100) {
      progressBar.setAttribute('aria-label', [
        this.dataset.currencySymbol,
        (priceAfterDiscountsExceptPromoGoalDiscounts / 100).toFixed(2),
        ' of ',
        this.dataset.currencySymbol,
        // threshold amounts will usually be rounded to a full dollar
        (thresholdAmount / 100).toString(),
        ' goal'
      ].join(''));
    } else {
      progressBar.setAttribute('aria-label', [
        this.dataset.currencySymbol,
        (thresholdAmount / 100).toString(),
        ' goal reached'
      ].join(''));
    }
  }

  private collectPromoGoalsData() {
    const elements = this.querySelectorAll('promo-goal');

    const goals: PromoGoal[] = [];
    for (const element of elements) {
      const goal = <PromoGoal>{};
      goal.currencyCode = element.dataset.currencyCode;
      goal.currencySymbol = element.dataset.currencySymbol;
      goal.isLocked = element.dataset.isLocked === 'true';
      goal.offerText = element.dataset.offerText;
      goal.thresholdCents = parseInt(element.dataset.thresholdCents, 10);
      goal.element = element;
      goals.push(goal);
    }

    return goals.sort(compareThresholds);
  }
}

function compareThresholds(a: PromoGoal, b: PromoGoal) {
  if (a.thresholdCents === b.thresholdCents) {
    return 0;
  }

  return a.thresholdCents < b.thresholdCents ? -1 : 1;
}

function calculateTotalDiscount(cart: Cart) {
  let total = 0;
  for (const application of cart.cart_level_discount_applications) {
    total += application.total_allocated_amount;
  }

  for (const item of cart.items) {
    for (const allocation of item.line_level_discount_allocations) {
      total += allocation.amount;
    }
  }

  return total;
}

function calculateTotalPriceOfPromoExcludedItems(cart: Cart) {
  let total = 0;

  // There are also $0 items to exclude, but since they are 0, we just ignore them since they do
  // not change the total.

  for (const item of cart.items) {
    if (item.gift_card) {
      total += item.final_line_price;
    }
  }

  return total;
}

function calculateTotalPromoGoalDiscount(cart: Cart) {
  let total = 0;

  for (const application of cart.cart_level_discount_applications) {
    if (isPromoNamedDiscount(application.title)) {
      total += application.total_allocated_amount;
    }
  }

  for (const item of cart.items) {
    for (const allocation of item.line_level_discount_allocations) {
      if (isPromoNamedDiscount(allocation.discount_application.title)) {
        total += allocation.discount_application.total_allocated_amount;
      }
    }
  }

  return total;
}

function isPromoNamedDiscount(value: string) {
  if (typeof value !== 'string') {
    return false;
  }

  const upper = value.toUpperCase();

  if (upper.startsWith('BUY MORE SAVE MORE')) {
    return true;
  }

  // This is a special $20 off that is allocated over line items. This is also a type of promo goal.
  if (upper.includes('$20 DISCOUNT APPLIED')) {
    return true;
  }

  if (/SPEND \$[0-9]+ GET [0-9]+% OFF/.test(upper)) {
    return true;
  }

  return false;
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

interface PromoGoal {
  currencyCode: string;
  currencySymbol: string;
  element: HTMLElement;
  isLocked: boolean;
  offerText: string;
  thresholdCents: number;
}

declare global {
  interface HTMLElementTagNameMap {
    'promo-goal-progress': PromoGoalProgress;
  }
}

if (!customElements.get('promo-goal-progress')) {
  customElements.define('promo-goal-progress', PromoGoalProgress);
}
