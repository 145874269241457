import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Contains cart-upsell-item elements.
 */
class CartUpsellItems extends HTMLElement implements CustomElement {
  private onCartUpdatedBound = this.onCartUpdated.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    // The animation frame ensures the element has actually rendered.
    requestAnimationFrame(() => {
      dispatchEvent(new Event('cart-upsell-items-rendered'));
    });

    for (const event of cart_drawer_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);
  }

  public disconnectedCallback() {
    removeEventListener('cart-updated', this.onCartUpdatedBound);
  }

  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    const variantIds = event.detail.cart.items.map(item => item.variant_id);
    const elements = this.querySelectorAll('cart-upsell-item');
    let visibleCount = 0;

    for (const element of elements) {
      const variantId = parseInt(element.dataset.variantId, 10);
      const show = !variantIds.includes(variantId) && visibleCount < 4;

      if (show) {
        // if upsellItemElement is not included in the cart unhide it
        element.style.display = 'flex';
        visibleCount++;
      } else {
        element.style.display = 'none';
      }
    }

    // Hide upsell items section if it is empty
    if (visibleCount === 0) {
      this.style.display = 'none';
    } else {
      this.style.display = 'block';
    }
  }
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

declare global {
  interface WindowEventMap {
    /**
     * Dispatched by the upsell items element whenever the upsell items element is rendered.
     */
    'cart-upsell-items-rendered': Event;
  }

  interface HTMLElementTagNameMap {
    'cart-upsell-items': CartUpsellItems;
  }
}

if (!customElements.get('cart-upsell-items')) {
  customElements.define('cart-upsell-items', CartUpsellItems);
}
