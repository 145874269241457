import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders a promo goal. Used in slot of promo-goal-progress.
 */
class PromoGoal extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-currency-code',
      'data-currency-symbol',
      'data-is-locked',
      'data-offer-text',
      'data-threshold-cents'
    ];
  }

  readonly dataset!: {
    /**
     * The ISO code of the currency
     *
     * @example USD
     */
    currencyCode: string;

    /**
     * Currency symbol
     *
     * @example $
     */
    currencySymbol: string;

    /**
     * The state of the goal
     */
    isLocked: 'false' | 'true';

    /**
     * Text thad describes the promotional goal
     */
    offerText: string;

    /**
     * The amount of money the customer needs to spend to unlock the promotional goal in cents
     */
    thresholdCents: string;
  };

  public shadowRoot!: ShadowRoot;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && newValue !== oldValue) {
      this.render();
    }
  }

  private render() {
    if (!this.dataset.thresholdCents ||
      !this.dataset.currencySymbol ||
      !this.dataset.currencyCode ||
      !this.dataset.offerText) {
      return;
    }

    const thresholdAmount = parseInt(this.dataset.thresholdCents || '0', 10);

    const label = this.shadowRoot.querySelector('.label');

    // if the promo goal does not have a spend threshold, then it is instantly applicable.
    // in this case we just need to render 'get y' not 'spend x, get y'
    label.innerHTML = thresholdAmount === 0 ?
      `Get ${this.dataset.offerText}!` :
      `Spend <price-element
      data-currency-symbol="${this.dataset.currencySymbol}"
      data-currency-code="${this.dataset.currencyCode}"
      data-cents="${this.dataset.thresholdCents}">
      </price-element>, get ${this.dataset.offerText}!`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'promo-goal': PromoGoal;
  }
}

if (!customElements.get('promo-goal')) {
  customElements.define('promo-goal', PromoGoal);
}
